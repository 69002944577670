import { requestEntity, requestTable } from '../../transport/api';
import { makeTitle } from '../../utils';
import React, { useEffect, useState } from 'react';
import AppContainer from '../../components/app-container';
import Cookies from 'js-cookie';

import styles from './card.module.css';
import AppTitle from '../../components/AppTitle';
import Indicator from '../../components/indicator';
import PieChart from '../../components/charts/PieChart';
import { LineChart } from '../../components/charts';
import Switch from '../../components/Switch';


const InvestorDashboard = () => {
  const investorId = Cookies.get('investorId');
  const [loading, toggleLoading] = useState(true);
  const [data, setData] = useState({});
  const [sharesData, setSharesData] = useState([]);
  const [profitData, setProfitData] = useState({ labels: [], data: [] });
  const [balanceData, setBalanceData] = useState({ labels: [], data: [] });
  const [view, setView] = useState('balance');

  useEffect(() => {
    requestEntity('/investors', investorId)
      .then((data) => {
        toggleLoading(false);
        setData({ ...data, id: data.id || investorId });
      })
    requestTable(`/investors/${investorId}/shares`, []).then((data) => setSharesData(data.list));
    requestTable(`/investors/${investorId}/profit`, [])
      .then((data) => setProfitData({ labels: data.list.map((item) => new Date(item.day).toLocaleDateString()), data: data.list.map((item) => Math.round(item.volume)) }));
    requestTable(`/investors/${investorId}/balance`, [])
      .then((data) => setBalanceData({ labels: data.list.map((item) => new Date(item.day).toLocaleDateString()), data: data.list.map((item) => Math.round(item.volume)) }));
  }, [investorId]);

  makeTitle(data?.name);

  return (
    <AppContainer title={
      <div className={styles['button-container']}>
        <AppTitle loading={loading}>{data?.name}</AppTitle>
      </div>
    }>
      <div className={styles['indicator-container']}>
        <Indicator
          key="current_balance"
          label="Текущий баланс"
          value={data?.current_balance}
          loading={loading}
          measure="₽"
        />
        <Indicator
          key="percent"
          label="Процент с доли"
          value={data?.percent}
          loading={loading}
          measure="%"
        />
        <Indicator
          key="year_profit"
          label="Годовая доходность"
          value={data?.year_profit}
          loading={loading}
          measure="₽"
        />
        <Indicator
          key="year_profit_percent"
          label="Процент годовой доходности"
          value={data?.year_profit_percent}
          loading={loading}
          measure="%"
        />
        <Indicator
          key="month_profit"
          label="Прибыль за месяц"
          value={data?.current_month_profit}
          loading={loading}
          measure="₽"
        />
      </div>
      <div className={styles['chart-container']}>
        <PieChart
          title="Доля"
          data={sharesData.map((item) => ({name: item.name, value: item.share}))}
          displayPercent
        />
        <LineChart
          title={(
            <div className={styles['switch-container']}>
              <div>Динамика</div>
              <Switch
                containerClassName={styles.switch}
                value={view}
                onChange={(e) => setView(e.target.value)}
                isFilter
                options={[
                  { value: 'balance', label: 'Баланс' },
                  { value: 'profit', label: 'Прибыль' },
                ]}
              />
            </div>
          )}
          labels={(view === 'balance' ? balanceData : profitData)?.labels}
          data={(view === 'balance' ? balanceData : profitData)?.data}
          startValue={Math.min(...(view === 'balance' ? balanceData : profitData)?.data)}
        />
      </div>
    </AppContainer>
  );
}

export default InvestorDashboard;