import React, { useContext, useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { buildOptions } from './options';

import ComponentContainer from '../../ComponentContainer';
import ThemeContext from '../../../ThemeContext';

const PieChart = ({
  data,
  title,
  displayPercent
}) => {
  const { theme } = useContext(ThemeContext);
  const [borderColor, setBorderColor] = useState('');
  const [textColor, setTextColor] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setBorderColor(getComputedStyle(document.body)?.getPropertyValue('--modal-background'));
      setTextColor(getComputedStyle(document.body)?.getPropertyValue('--text-color'));
      }, 0
    );
  }, [theme]);

  return data ? (
    <ComponentContainer title={title}>
      <ReactECharts
        style={{ height: '26em' }}
        option={buildOptions(data, title, borderColor, textColor, displayPercent)}
      />
    </ComponentContainer>
  ) : undefined;
};

export default PieChart;
