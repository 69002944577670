import React, { useState } from 'react';
import AddTitle from '../../components/AddTitle';
import AppContainer from '../../components/app-container';
import AppTable from '../../components/AppTable';
import Select from '../../components/Select';
import { useNavigate } from 'react-router-dom';
import EditModal from '../../components/EditModal';
import InformationCreateForm from './InformationForm';
import { makeTitle, useFilters } from '../../utils';

const Supplies = () => {
  makeTitle('Себестоимость')
  const navigate = useNavigate();

  const { filters, setFilter } = useFilters();

  const [newSupply, toggleNewSupply] = useState(false);

  return (
    <AppContainer
      title={<AddTitle text='Партии' onAddClick={() => toggleNewSupply(true)} />}
      filters={[
        <Select
          value={filters.get('sellerId')}
          onChange={(value) => setFilter('sellerId', value)}
          resourcePath="/sellers"
          optionsColumns={{ value: 'id', label: 'name' }}
          label="Продавец"
        />,
        <Select
          value={filters.get('goodsVariantId')}
          onChange={(value) => setFilter('goodsVariantId', value)}
          resourcePath="/goods-variants"
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
          label="Артикул"
          filters={[
            { fieldName: 'seller_id', value: filters.get('sellerId') },
            { fieldName: 'exclude_same_variants', value: true },
          ]}
        />,
      ]}
    >
      <AppTable
        columns={[
          { name: 'media_url', title: '', canSort: false, type: 'image', align: 'start' },
          { name: 'name', title: 'Название', canSort: false },
          { name: 'vendor_code', title: 'Артикул', canSort: false },
          { name: 'supply_date', title: 'Дата партии', type: 'datetime' },
          { name: 'volume', title: 'Объём, шт.', canSort: false },
          { name: 'remaining_volume', title: 'Осталось, шт.', canSort: false },
          { name: 'cost_price', title: 'Себестоимость', canSort: false, type: 'number' },
          { name: 'use_in_turnover', title: 'В обороте?', type: 'forceBool' },
        ]}
        onClick={(row) => navigate(`/supplies/${row.id}`)}
        resourcePath='/supplies'
        defaultSorting={[{ id: 'supply_date', desc: true }]}
        filters={[
          { fieldName: 'seller_id', value: filters.get('sellerId') },
          { fieldName: 'goods_variant_id', value: filters.get('goodsVariantId') },
        ]}
      />
      <EditModal
        isOpen={newSupply}
        close={() => toggleNewSupply(false)}
      >
        <InformationCreateForm />
      </EditModal>
    </AppContainer>
  )
}

export default Supplies;
