import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { createRecord } from '../../../transport/api';
import { toast } from 'react-toastify';
import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';
import UncontrolledSelect from '../../../components/UncontrolledSelect';
import Switch from '../../../components/Switch';


const NewCredit = () => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      is_initialization: 'init-false',
    }
  });
  const navigate = useNavigate();
  const [isInit, toggleInit] = useState(false);

  const onSubmit = async (data) => {
    createRecord('/credits', { ...data, is_initialization: data.is_initialization === 'init-true' })
      .then((responseData) => {
        toast.success('Кредит успешно создан');
        navigate(`/credits/${responseData.id}`);
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer title="Основная информация">
      <form onSubmit={handleSubmit(onSubmit)} id="credit-form">
        <Input
          label="Название"
          register={() =>
            register('name', {
              required: true,
            })
          }
        />
        <Input
          label="Сумма"
          type="number"
          step="0.01"
          register={() =>
            register('volume', {
              required: true,
            })
          }
        />
        <Input
          label="Процентная ставка (годовых)"
          type="number"
          step="0.01"
          register={() =>
            register('percent', {
              required: true,
            })
          }
        />
        <Input
          label="Срок (в месяцах)"
          type="number"
          register={() =>
            register('months_number', {
              required: true,
            })
          }
        />
        <Input
          label="Дата первого платежа"
          type="date"
          register={() =>
            register('first_payment_date', {
              required: true,
            })
          }
        />
        <Input
          label="Ежемесячный платёж"
          type="number"
          register={() =>
            register('month_payment', {
              required: true,
            })
          }
        />
        <Switch
          label="Добавить денежную операцию?"
          options={[
            { value: 'init-false', label: 'Да' },
            { value: 'init-true', label: 'Нет' },
          ]}
          register={() => register('is_initialization', {
            onChange: (e) => toggleInit(e.target.value === 'init-true'),
          })}
        />
        {!isInit ? (
          <UncontrolledSelect
            control={control}
            required={!isInit}
            name="physical_balance_id"
            label="Баланс зачисления"
            resourcePath="/physical-balances"
            loadOnFocus
            optionsColumns={{ value: 'id', label: 'name' }}
          />
        ) : undefined}
      </form>
      <Button form="credit-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default NewCredit;
