import React, { useEffect, useState } from 'react';
import { makeTitle } from '../../../utils';
import { requestEntity, updateRecord } from '../../../transport/api';
import { useParams } from 'react-router-dom';
import AppTitle from '../../../components/AppTitle';
import Indicator from '../../../components/indicator';
import Switch from '../../../components/Switch';
import AppContainer from '../../../components/app-container';
import styles from './credit.module.css';
import RecordTable from '../../../components/RecordTable';
import EmojiButton from '../../../components/EmojiButton';
import EditModal from '../../../components/EditModal';
import PayPayment from './PayPayment';
import EditPayment from './EditPayment';
import AddTitle from '../../../components/AddTitle';


const Credit = () => {
  const { creditId } = useParams();
  const [data, setData] = useState({});
  const [loading, toggleLoading] = useState(true);
  const [reload, toggleReload] = useState(false);
  const [newPaymentPay, setNewPaymentPay] = useState({});
  const [editPaymentId, setEditPaymentId] = useState(undefined);
  const [isNewPayment, toggleNewPayment] = useState(false);
  useEffect(() => {
    requestEntity('/credits', creditId)
      .then((data) => {
        toggleLoading(false);
        setData(data);
      })
  }, [creditId, reload, newPaymentPay?.id, isNewPayment, editPaymentId]);
  makeTitle(data?.name);

  const closePayment = () => {
    if (editPaymentId) {
      setEditPaymentId(undefined);
    }
    if (isNewPayment) {
      toggleNewPayment(false)
    }
  }

  return (
    <AppContainer title={
      <div className={styles['button-container']}>
        <AppTitle loading={loading}>{data?.name}</AppTitle>
        <div className={styles.switch}>
          <Switch
            value={`${data?.is_active}`}
            onChange={(e) => updateRecord(`/credits/${creditId}`, { is_active: e.target.value === 'true' }).then(() => toggleReload(!reload))}
            options={[
              { value: 'true', label: 'Активный' },
              { value: 'false', label: 'Закрытый' },
            ]}
          />
        </div>
      </div>
    }>
      <div className={styles['indicator-container']}>
        <Indicator
          key="current_balance"
          label="Тело долга"
          value={data?.volume}
          loading={loading}
          measure="₽"
        />
        <Indicator
          key="percent"
          label="Ставка"
          value={data?.percent}
          loading={loading}
          measure="%"
        />
        <Indicator
          key="status"
          label="Оставшийся долг"
          value={data?.remaining_debt}
          loading={loading}
          measure="₽"
        />
        <Indicator
          key="next_payment_date"
          label="Дата следующего платежа"
          value={(
            <div>
              <div>{data?.next_payment_date ? new Date(data?.next_payment_date).toLocaleDateString() : '-'}</div>
            </div>
          )}
          loading={loading}
          needPrepare={false}
          postfix={
            <EmojiButton
              disabled={!data?.next_payment_date || !data?.is_active}
              onClick={() => setNewPaymentPay({
                id: data?.next_payment_id,
                date: data?.next_payment_date,
              })}
              emoji="👛"
            />
          }
        />
      </div>
      <div>
        <RecordTable
          title={
            <AddTitle
              text="Платежи"
              disabled={!data?.is_active}
              onAddClick={() => toggleNewPayment(true)}
            />
          }
          columns={[
            { name: 'payment_date', title: 'Дата', type: 'date' },
            { name: 'volume', title: 'Сумма', type: 'number' },
            { name: 'interest_volume', title: 'Проценты', type: 'number' },
            { name: 'body_volume', title: 'Тело', type: 'number' },
            { name: 'is_paid', title: 'Оплачен?', type: 'forceBool' },
          ]}
          resourcePath={`/credits/${creditId}/payments`}
          onClick={(row) => setEditPaymentId(row.id)}
          defaultSorting={[{ id: 'payment_date' }]}
          restContextItems={[
            {
              id: 'pay',
              label: '👛 Оплатить',
              onClick: (row) => setNewPaymentPay({ id: row.id, date: row.payment_date }),
            }
          ]}
          forceUpdate={`${newPaymentPay?.id} ${isNewPayment} ${editPaymentId} ${reload}`}
          getDeleteProperties={(row) => ({
            path: `/credits/${creditId}/payments/${row.id}`, callback: () => toggleReload(!reload) })}
        />
      </div>
      <EditModal isOpen={!!newPaymentPay?.id} close={() => setNewPaymentPay({})}>
        <PayPayment
          creditId={creditId}
          payment={newPaymentPay}
          close={() => setNewPaymentPay({})}
        />
      </EditModal>
      <EditModal
        isOpen={editPaymentId || isNewPayment}
        close={closePayment}
      >
        <EditPayment paymentId={editPaymentId} creditId={creditId} close={closePayment} />
      </EditModal>
    </AppContainer>
  )
}

export default Credit;