import { makeTitle } from '../../utils';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AddTitle from '../../components/AddTitle';
import AppContainer from '../../components/app-container';
import RecordTable from '../../components/RecordTable';
import styles from './LoanFunds.module.css';
import ChangeInvestor from './investor/ChangeInvestor';
import EditModal from '../../components/EditModal';
import Button from '../../components/button';
import EmojiButton from '../../components/EmojiButton';
import NewCredit from './credit/create';
import LineChart from '../../components/charts/LineChart';
import Switch from '../../components/Switch';
import { requestStatistic, requestTable } from '../../transport/api';
import PieChart from '../../components/charts/PieChart';
import Indicator from '../../components/indicator';


const LoanFunds = () => {
  makeTitle('Заёмные средства');
  const navigate = useNavigate();

  const [isNewInvestor, toggleNewInvestor] = useState(false);
  const [showHiddenCredits, toggleShowHiddenCredits] = useState(false);
  const [isNewModalOpened, toggleNewModalOpened] = useState(false);
  const [chartView, setChartView] = useState('balance')
  const [balanceData, setBalanceData] = useState({labels: [], data: []});
  const [profitData, setProfitData] = useState({labels: [], data: []});
  const [sharesData, setSharesData] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    requestStatistic('/group-investor/dynamic').then((data) => {
      setBalanceData(
        data.reduce((acc, item) => {
          acc.labels.push(new Date(item.operation_date)?.toLocaleDateString());
          acc.data.push(item.balance);
          return acc;
        }, { labels: [], data: [] })
      );
      setProfitData(
        data.reduce((acc, item) => {
          acc.labels.push(new Date(item.operation_date)?.toLocaleDateString());
          acc.data.push(item.profit);
          return acc;
        }, { labels: [], data: [] })
      );
      requestTable(`/group-investor/shares`, []).then((data) => setSharesData(data.list));
      requestStatistic('/group-investor/summary').then((data) => {
        setData(data);
      });
    })
  }, []);

  return (
    <AppContainer title="Заёмные средства" containerClassname={styles.container}>
      <div className={styles['indicator-container']}>
        <Indicator
          key="share"
          label="Текущая доля группы"
          value={data?.share}
          loading={data?.share === undefined}
        />
        <Indicator
          key="profit"
          label="Доходность за месяц"
          value={data?.month_profit}
          loading={data?.month_profit === undefined}
        />
      </div>
      <div className={styles['chart-container']}>
        <PieChart
          title="Доля"
          data={sharesData.map((item) => ({name: item.name, value: item.share}))}
          displayPercent
        />
        <LineChart
          title={(
            <div className={styles['switch-container']}>
              <div>Динамика</div>
              <Switch
                containerClassName={styles.switch}
                value={chartView}
                onChange={(e) => setChartView(e.target.value)}
                isFilter
                options={[
                  { value: 'balance', label: 'Баланс' },
                  { value: 'profit', label: 'Прибыль' },
                ]}
              />
            </div>
          )}
          leftOffset={8}
          labels={(chartView === 'balance' ? balanceData : profitData)?.labels}
          data={(chartView === 'balance' ? balanceData : profitData)?.data}
          startValue={Math.min(...(chartView === 'balance' ? balanceData : profitData)?.data)}
        />
      </div>
      <div>
        <RecordTable
          title={<AddTitle text="Инвесторы" onAddClick={() => toggleNewInvestor(true)} /> }
          containerClassName={styles['mini-table']}
          componentContainer={styles['mini-table']}
          columns={[
            { name: 'name', title: 'Имя'},
            { name: 'status_desc', title: 'Статус' },
            { name: 'current_balance', title: 'Текущий баланс', type: 'number' },
            { name: 'percent', title: 'Процент', type: 'number' },
            { name: 'year_profit_percent', title: 'Доходность за год', type: 'number' },
          ]}
          onClick={(row) => navigate(`/investors/${row.id}`)}
          resourcePath='/investors'
          defaultSorting={[{ id: 'name', desc: true }]}
        />
      </div>
      <div>
        <RecordTable
          componentContainer={styles['mini-table']}
          containerClassName={styles['mini-table']}
          title={(
            <div className={styles['credit-button-container']}>
              <div>
                Кредиты
              </div>
              <Button className={styles['add-button']} onClick={() => toggleNewModalOpened(true)}>+</Button>
              <EmojiButton
                emoji={showHiddenCredits ? '🐵' : '🙈'}
                title="Показать закрытые кредиты?"
                onClick={() => {
                  toggleShowHiddenCredits(!showHiddenCredits)
                }}
                className={styles['hidden-button']}
              />
            </div>
          )}
          columns={[
            { name: 'name', title: 'Название' },
            { name: 'volume', title: 'Тело', type: 'number' },
            { name: 'percent', title: 'Ставка' },
            { name: 'remaining_debt', title: 'Остаток долга', type: 'number' },
            { name: 'next_payment_date', title: 'Дата следующего платежа', type: 'date' },
          ]}
          onClick={(row) => navigate(`/credits/${row.id}`)}
          filters={[
            { fieldName: 'is_closed_showing', value: showHiddenCredits },
          ]}
          resourcePath='/credits'
        />
      </div>
      <EditModal close={() => toggleNewInvestor(false)} isOpen={isNewInvestor} className={styles['new-investor']}>
        <ChangeInvestor />
      </EditModal>
      <EditModal isOpen={isNewModalOpened} close={() => toggleNewModalOpened(false)}>
        <NewCredit />
      </EditModal>
    </AppContainer>
  )
}

export default LoanFunds;