import styles from './card.module.css';
import Indicator from '../../../components/indicator';
import ComponentContainer from '../../../components/ComponentContainer';
import RecordTable from '../../../components/RecordTable';
import EditModal from '../../../components/EditModal';
import Balance from './Balance';
import Operation from '../../operations/Operation';
import Input from '../../../components/input';
import Select from '../../../components/Select';
import React, { useEffect, useState } from 'react';
import { requestEntity } from '../../../transport/api';


const Cash = ({ goodsId, isGoodsEdit, isLinkVariantsOpen, newOperation }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState();
  const [operation, setOperation] = useState();

  useEffect(() => {
    requestEntity('/goods', goodsId).then((data) => {
      setData(() => data);
      setLoading(false);
    });
  }, [goodsId, isGoodsEdit, newOperation, balance, isLinkVariantsOpen]);

  return (
    <div>
      <div className={styles['indicator-container']}>
        <Indicator
          key="cash_balance"
          label="Баланс"
          value={data?.cash_balance}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="total_supply_balance"
          label="Товарный баланс"
          value={data?.goods_balance}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="balance_summary"
          label="Общий баланс"
          value={data?.cash_balance + data?.goods_balance - data?.tax}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="tax"
          label="Неоплаченный налог"
          value={data?.tax}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="profit"
          label="Прибыль"
          value={Math.round(data?.profit)}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="revenue"
          label="Выручка"
          value={Math.round(data?.revenue)}
          measure="₽"
          loading={loading}
        />
        <Indicator
          key="sales"
          label="Продажи"
          value={data?.sales}
          measure="шт."
          loading={loading}
        />
      </div>
      <div className={styles['image-table-container']}>
        <ComponentContainer loading={loading}>
          <img
            src={data?.media_url || '/icons/Image 2.svg'}
            alt="Изображение проекта"
            className={`${styles.image} ${
              !data?.media_url ? styles['no-image'] : ''
            }`}
          />
        </ComponentContainer>
        <RecordTable
          title="Балансы"
          containerClassName={styles['balance-table']}
          componentContainer={styles['balance-table']}
          columns={[
            { name: 'physical_balance_name', title: 'Физический баланс' },
            { name: 'initial', title: 'Первоначальный баланс', type: 'number' },
            {
              name: 'initial_date',
              title: 'Дата первоначального баланса',
              type: 'date',
            },
            { name: 'cash_current_value', title: 'Текущий остаток', type: 'number' },
            { name: 'is_main', title: 'Основной баланс', type: 'bool' },
          ]}
          resourcePath={`/goods/${goodsId}/balances`}
          onClick={(row) =>
            setBalance({
              id: row.id,
              initial: row.initial,
              name: row.name,
              initial_date: row.initial_date,
              is_main: row.is_main,
            })
          }
          forceUpdate={!!balance?.id}
          defaultSorting={[{ id: 'current_value', desc: true }]}
        />
        <EditModal close={() => setBalance(undefined)} isOpen={!!balance?.id}>
          <Balance
            close={() => setBalance(undefined)}
            goodsId={goodsId}
            balance={balance}
          />
        </EditModal>
      </div>
      <div className={styles['chart-container']}>
        <RecordTable
          title="История последних транзакций"
          columns={[
            { name: 'operation_date', title: 'Дата', type: 'date', customStyle: styles['fixed-column'] },
            {
              name: 'volume',
              title: 'Сумма',
              type: 'number',
              customStyle: styles['fixed-column'],
            },
            {
              name: 'goods_volume',
              title: 'Тов. сумма',
              type: 'number',
              customStyle: styles['fixed-column'],
            },
            {
              name: 'operation_type_desc',
              title: 'Тип',
              customStyle: styles['fixed-column'],
            },
            { name: 'balance_from_name', title: 'Баланс списания', customStyle: styles['balance-column'] },
            { name: 'balance_to_name', title: 'Баланс зачисления', customStyle: styles['balance-column'] },
            { name: 'description', title: 'Комментарий' },
          ]}
          resourcePath={`/goods/${goodsId}/operations`}
          defaultSorting={[{ id: 'operation_date', desc: true }]}
          forceUpdate={`${newOperation} ${!!operation?.newOperation}`}
          onClick={(row) => {
            setOperation({
              newOperation: row.operation_type,
              editingOperationId: row.main_operation_uid,
              defaultVolume: row.volume,
              defaultFromBalanceName: row.balance_from_name,
              defaultToBalanceName: row.balance_to_name
            })
          }}
          getDeleteProperties={(row) => ({ path: `/money-operations/${row.main_operation_uid}`})}
        />
        <EditModal close={() => setOperation(undefined)} isOpen={!!operation?.newOperation}>
          <Operation
            close={() => setOperation()}
            newOperation={operation?.newOperation}
            editingOperationId={operation?.editingOperationId}
            investorId={operation?.investorId}
            defaultVolume={operation?.defaultVolume}
            defaultFromBalanceName={operation?.defaultFromBalanceName}
            defaultToBalanceName={operation?.defaultToBalanceName}
            onlyShared={operation?.onlyShared}
          />
        </EditModal>
      </div>
    </div>
  )
}

export default Cash;
