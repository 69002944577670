export const buildOptions = (
    originalData, 
    height, 
    tooltip_options = undefined, 
    yAxisLabelShow = false,
    secondaryColor
  ) => {

  const extend_tooltip_with = tooltip_options || undefined;

  const showYAxisLabel = yAxisLabelShow;

  //Creating data for chart
  const originalDataValues = Object.values(originalData);

  const maxValue = Math.min(
      Math.max(...originalDataValues.filter((v) => v.turnover !== 9999).map((v) => v.turnover)),
      180
    );

    const chartData = originalDataValues.map((value) => { 
      if (extend_tooltip_with) {
        let base = [
          value.turnover === 9999 ? maxValue + 2 : value.turnover > 180 ? 181 : value.turnover,
          value.warehouse_name,
          value.color === 'ok' ? '#26e24f' : value.color === 'bad' ? '#e7b537' : '#e74c3c',
        ];
        for (let entry of extend_tooltip_with) {
          base.push(value[Object.keys(entry)[0]])
        }
        return base
      }
      return [
        value.turnover === 9999 ? maxValue + 2 : value.turnover > 180 ? 181 : value.turnover,
        value.warehouse_name,
        value.color === 'ok' ? '#26e24f' : value.color === 'bad' ? '#e7b537' : '#e74c3c',
      ]
    });

  const chartElementColors = Object.fromEntries(chartData.map(([_, name, color]) => [name, color]));

  const gridTop = 64;

  let myHeight = height + (
    Math.sqrt(chartData.length) + 10
  ); // scale of a chart

  return {
    tooltip: {
      show: true,
      formatter: function(param) {
        let base_tooltip = `${param.data[1]} ${(param.data[0] === maxValue + 2
          ) ? '♾️' : (param.data[0] === 181) ? '180+' : Math.round(param.data[0]) + ''}`
        if (extend_tooltip_with) {
          let extensions = '';
          let count = 2;
          for (let extension of extend_tooltip_with) {
            count += 1;
            extensions += Object.keys(extension)[0] !== 'id' 
            ? (`<br />${[Object.values(extension)[0]]} ${Math.round(param.data[count])}`) : '';
          }
          return `${base_tooltip} ${extensions}`;
        }
        return `${base_tooltip}`;
      }
    },
    height: myHeight,
    grid: {
      top: gridTop, // do not touch, otherwise bars won't correspond with table rows
      left: showYAxisLabel ? 250 : 10,
      right: 100,
    },
    xAxis: {
      axisLabel: {
        formatter: function (n) {
          return Math.round(n) + '';
        }
      },
    },
    textStyle: {
      fontFamily: 'Nunito',
      // fontSize: 16
    },
    dataset: {
      source: chartData,
    },
    yAxis: {
      type: 'category',
      inverse: true,
      data: [
        ...chartData.map((item) =>
          item[1],
        ),
      ],
      axisLabel: {
        show: showYAxisLabel,
        overflow: 'break',
        fontFamily: 'Nunito',
        color: secondaryColor
      },
    },
    series: [
      {
        barCategoryGap: 10,
        seriesLayoutBy: 'column',
        type: 'bar',
        itemStyle: {
          color: function (param) { //param.value = 1 instance from data
            return chartElementColors[param.value[1]] || '#6E7079';
          },
        },
        encode: {
          x: 0,
          y: 1,
        },
        label: {
          show: true,
          precision: 0,
          position: 'right',
          fontSize: 14,
          valueAnimation: true,
          formatter: function(n) {
            return (
            n.value[0] === maxValue + 2
          ) ? '♾️' : (n.value[0] === 181) ? '180+' : Math.round(n.value[0]) + ''}
        },
      },
    ]
  };
};
