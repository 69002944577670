import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { requestEntity, requestStatistic, requestTable } from '../../../transport/api';

import AppTitle from '../../../components/AppTitle';
import AppContainer from '../../../components/app-container';
import { LineChart, HorizontalBar } from '../../../components/charts';
import Indicator from '../../../components/indicator';
import Input from '../../../components/input';
import RecordTable from '../../../components/RecordTable';
import Select from '../../../components/Select';

import styles from './goods-variant-turnover-card.module.css';

import { getDate, makeTitle } from '../../../utils';
import Skeleton from 'react-loading-skeleton';
import EmojiButton from '../../../components/EmojiButton';
import TriggerModal from '../../../components/TriggerModal';


const GoodsVariantTurnoverCard = () => {
  const [diagramLoading, setDiagramLoading] = useState(true);

  const tableBodyRef = useRef();
  const containerRef = useRef();
  const secondDivRef = useRef();

  const { goodsVariantId } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [periodStart, setPeriodStart] = useState(getDate('date', -7));
  const [periodEnd, setPeriodEnd] = useState(getDate('date'));

  const [submitPeriodStart, setSubmitPeriodStart] = useState(periodStart);
  const [submitPeriodEnd, setSubmitPeriodEnd] = useState(periodEnd);

  const [isTriggerSetShow, toggleTriggerSetShow] = useState(false);

  const [chartData, setChartData] = useState({});
  const [horizontalBarData, setHorizontalBarData] = useState({});
  const [warehouseId, setWarehouseId] = useState(undefined);

  makeTitle(`Оборачиваемость ${data?.vendor_code}`);

  useEffect(() => {
    requestEntity('/turnover', goodsVariantId, {
      period_start: submitPeriodStart,
      period_end: submitPeriodEnd,
    }).then((data) => {
      setData(() => data);
      setLoading(false);
    });

    requestStatistic(`/turnover/${goodsVariantId}/orders`, [
      { fieldName: 'period_start', value: submitPeriodStart },
      { fieldName: 'period_end', value: submitPeriodEnd },
      { fieldName: 'warehouse_id', value: warehouseId },
    ]).then((data) => setChartData(
      data.list.reduce((acc, order) => {
        return {
          ...acc,
          [order.date]: order.order_amount,
        };
      }, {}),
    ));

    requestTable(`/turnover/${goodsVariantId}/warehouses`, [
      { fieldName: 'period_start', value: submitPeriodStart },
      { fieldName: 'period_end', value: submitPeriodEnd },
      { fieldName: 'page', value: 1 },
      { fieldName: 'page_size', value: 400 },
      { fieldName: 'order_column', value: 'turnover' },
    ], [
      { id: 'turnover', desc: false },
    ]).then((data) => setHorizontalBarData(data.list));
  }, [goodsVariantId, submitPeriodStart, submitPeriodEnd, warehouseId, isTriggerSetShow]);


  const handleScrollFirst = useCallback(() => {
    secondDivRef.current.scrollTop = containerRef.current.scrollTop;
  }, []);

  const handleScrollSecond = useCallback(() => {
    containerRef.current.scrollTop = secondDivRef.current.scrollTop;
  }, []);

  useEffect(() => {
    setTimeout(() => setDiagramLoading(false), 500);
      containerRef?.current?.addEventListener("scroll", handleScrollFirst, { capture: true });
  }, [handleScrollFirst]);


  useEffect(() => {
    setTimeout(() => setDiagramLoading(false), 500);
      containerRef?.current?.addEventListener("scroll", handleScrollFirst, { capture: true });
  }, [handleScrollFirst]);

  return (
    <AppContainer
      title={<AppTitle loading={loading}>{data?.vendor_code}</AppTitle>}
      filters={[
        <div>Среднее за период:</div>,
        <Input
          type="date"
          value={periodStart}
          onChange={(e) => setPeriodStart(() => e.target.value)}
          options={{
            onBlur: () => setSubmitPeriodStart(periodStart),
          }}
          isFilter
        />,
        <div>—</div>,
        <Input
          type="date"
          value={periodEnd}
          onChange={(e) => setPeriodEnd(() => e.target.value)}
          options={{
            onBlur: () => setSubmitPeriodEnd(periodEnd),
          }}
          isFilter
        />,
      ]}
    >
      <div className={styles['indicator-container']}>
        <Indicator
          key="turnover"
          label="Оборачиваемость общая"
          value={data?.turnover}
          loading={loading}
        />
        <Indicator
          key="wb_turnover"
          label="Оборачиваемость WB"
          value={data?.wb_turnover}
          loading={loading}
        />
        <Indicator
          key="period_orders"
          label="Заказы за период"
          value={data?.period_orders}
          measure="шт."
          loading={loading}
        />
        <Indicator
          key="avg_period_orders"
          label="Средние заказы"
          value={data?.avg_period_orders}
          measure="шт."
          loading={loading}
        />
        <Indicator
          key="all_remaining_volume"
          label="Остаток общий"
          value={data?.all_remaining_volume}
          measure="шт."
          loading={loading}
        />
        <Indicator
          needPrepare={false}
          key="formula"
          label="Остаток WB = Склад + От клиента + К клиенту * ( 1 - % выкупа)"
          value={
            <div>
              <div>{data?.marketplace_units_left} = {data?.quantity} + {data?.in_way_from_client} + {data?.in_way_to_client} * (1 - {data?.sales_percent?.toFixed(2)} ) </div>
            </div>
          }
          measure=""
          loading={loading}
        />
        <Indicator
          key="marketplace_units_left"
          label="Остаток наш склад"
          value={data?.remaining_supply_volume}
          measure="шт."
          loading={loading}
        />
        <Indicator
          key="problem"
          label="Проблемные склады"
          value={data?.problem}
          measure="шт."
          loading={loading}
        />
      </div>
      <div className={styles['containerOuter']}>
        <div className={styles['localTable']}>
          <RecordTable
            title={(
              <div className={styles['table-container']}>
                <div>Оборачиваемость по складам</div>
                <EmojiButton
                  emoji="💢"
                  className={styles['trigger-button']}
                  onClick={() => toggleTriggerSetShow(true)}
                  title="Установить триггеры"
                />
              </div>
            )}
            columns={[
              { name: 'warehouse_name', title: 'Склад', canSort: false },
              {
                name: 'marketplace_units_left',
                title: 'Остатки WB',
                type: 'number',
                canSort: false,
              },
              {
                name: 'avg_period_orders',
                title: 'Ср. заказы',
                type: 'number',
                canSort: false,
              },
              // {
              //   name: 'turnover',
              //   title: 'Оборачиваемость',
              //   type: 'number',
              //   canSort: false,
              // },
            ]}
            filters={[
              { fieldName: 'period_start', value: submitPeriodStart },
              { fieldName: 'period_end', value: submitPeriodEnd },
            ]}
            forceUpdate={isTriggerSetShow}
            resourcePath={`/turnover/${goodsVariantId}/warehouses`}
            defaultSorting={[{ id: 'turnover' }]}
            ref={{ tableBodyRef, containerRef }}
          />
        </div>
        <div className={styles['more-outer-boxes']}>
          <div className={styles['chart-shadow']}></div>
          <div className={styles['chartBox']}>
            <div ref={secondDivRef} onScroll={handleScrollSecond} className={styles['container']}>
              <div style={
                {
                  height: (
                    (tableBodyRef?.current?.scrollHeight || 0) + 110
                  )
                }}>
                {
                  horizontalBarData && !diagramLoading ?
                    <HorizontalBar
                      title={'Оборачиваемость по складам'}
                      data={horizontalBarData}
                      height={tableBodyRef?.current?.scrollHeight || '28.5em'}
                    />
                    : <Skeleton height="28.5em"/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['chart-container']}>
        {chartData ? (
          <LineChart
            title={
              <div className={styles['chart-title']}>
                <div>
                  Заказы
                </div>
                <div className={styles['chart-filter']}>
                  <Select
                    onChange={(value) => setWarehouseId(() => value)}
                    resourcePath={`/turnover/${goodsVariantId}/warehouses`}
                    filters={[
                      { fieldName: 'period_start', value: submitPeriodStart },
                      { fieldName: 'period_end', value: submitPeriodEnd },
                    ]}
                    optionsColumns={{ value: 'id', label: 'warehouse_name' }}
                    sorting={[{ id: 'turnover' }]}
                    label="Склад"
                  />
                </div>
              </div>
            }
            labels={Object.keys(chartData)}
            data={Object.values(chartData)}
          />
        ) : undefined}
      </div>
      <TriggerModal
        isOpen={isTriggerSetShow}
        close={() => toggleTriggerSetShow(false)}
        columns={[
          {
            key: 'warehouse_name',
            title: 'Склад',
          },
          {
            key: 'trigger_mp',
            title: 'Триггер ВБ',
          },
        ]}
        url={`/turnover/${goodsVariantId}/triggers`}
      />
    </AppContainer>
  );
};

export default GoodsVariantTurnoverCard;
