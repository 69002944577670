import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { requestOptions, updateRecord } from '../../../transport/api';
import { getISODateWOTZ } from '../../../utils';

import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';
import Switch from '../../../components/Switch';

const GoodsEdit = ({ close, initial_date, goodsId, isSharedEdit }) => {
  const [goodsData, setGoodsData] = useState();

  useEffect(() => {
    requestOptions(`/goods/${goodsId}/info`, []).then((data) => setGoodsData(data));
  }, [goodsId]);



  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      initial_date: getISODateWOTZ(initial_date) || null,
    },
  });

  useEffect(() => {
    if (goodsData) {
      setValue('initial_revenue', goodsData.initial_revenue);
      setValue('initial_profit', goodsData.initial_profit);
      setValue('is_used_in_shared', goodsData.is_used_in_shared.toString());
    }
  }, [goodsData, setValue])

  const onSubmit = async (data) => {
    return updateRecord(`/goods/${goodsId}`, data)
      .then(() => {
        toast.success('Проект успешно обновлён');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer>
      <form onSubmit={handleSubmit(onSubmit)} id="percent-form">
        <Input
          label={!!initial_date ? undefined : "Выручка"}
          type="number"
          hidden={!!initial_date || !!isSharedEdit}
          register={() => register('initial_revenue')}
        />
        <Input
          label={!!initial_date ? undefined : "Прибыль"}
          type="number"
          hidden={!!initial_date || !!isSharedEdit}
          register={() => register('initial_profit')}
        />
        <Input
          label={!!initial_date ? undefined : "Дата инициализации"}
          type="datetime-local"
          hidden={!!initial_date || !!isSharedEdit}
          register={() => register('initial_date')}
        />
        {isSharedEdit ? (
          <Switch
            label="Участвует в общих операциях?"
            options={[
              { value: 'true', label: 'Да' },
              { value: 'false', label: 'Нет' },
            ]}
            register={() =>
              register('is_used_in_shared', {
                onChange: (e) => setValue('is_used_in_shared', e.target.value),
              })
            }
          />
        ) : undefined}
      </form>
      <Button form="percent-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default GoodsEdit;
