import ReactModal from 'react-modal';
import styles from './list.module.css';
import React, { useEffect, useState } from 'react';
import { createRecord, requestTable } from '../../../transport/api';
import Button from '../../../components/button';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import dayjs from 'dayjs';

const PaymentManagementModal = ({ isOpen, close }) => {
  const [data, setData] = useState([]);
  const [loading, toggleLoading] = useState(true);
  const [update, toggleUpdate] = useState(false);

  useEffect(() => {
    requestTable('/money-operations/platform-inputs', [], [{id: 'start_date', desc: true}])
      .then((data) => {
        setData(data.list);
        toggleLoading(false);
      });
  }, [update]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={`${styles['modal-container']} ${styles['payment-modal']}`}
      overlayClassName={styles['modal-overlay']}
      ariaHideApp={false}
      onRequestClose={() => close()}
    >
      <div className={styles['operation-container']}>
        {loading ? new Array(20).fill(null).map(() => (
          <Skeleton inline height="2.5em" borderRadius="5px"/>
        )) : (
          <div className={styles['operation-container']}>
            {data.map((item) => (
              <Button
                key={item.id}
                className={styles.operation}
                disabled={item.is_paid}
                onClick={() => {
                  toggleLoading(true);
                  createRecord(
                    '/money-operations/create-platform-inputs',
                    {
                      start_date: item.start_date,
                      end_date: item.end_date,
                      seller_id: item.seller_id,
                      platform_id: item.platform_id,
                    }
                  )
                    .then(() => {
                      toggleUpdate(!update);
                      toast.success('Операции успешно созданы 🎉')
                    })
                    .catch(() => toast.error('Произошла ошибка'))
                }}
              >
                <div className={styles['operation-buttons']}>
                  <div key="key-values" className={styles['buttons-group']}>
                    <div key="description">{dayjs(item.start_date).format('DD.MM')}-{dayjs(item.end_date).format('DD.MM')}</div>
                    <div key="seller">{item.seller_name}</div>
                    <img
                      key="platform-logo"
                      className={styles['platform-logo']}
                      src={
                        item.platform_name === 'wb' ? 'https://www.wildberries.ru/favicon.ico' :
                        item.platform_name === 'ozon' ? 'https://st.ozone.ru/assets/favicon.ico' : ''
                      }
                      alt="platfrom"
                    />
                  </div>
                  <div key="payment" className={styles['buttons-group']}>
                    <div key="amount">{item.payment}</div>
                    <div>{item.is_paid ? '🔒' : undefined}</div>
                  </div>
                </div>
              </Button>))}
          </div>
        )}
      </div>
    </ReactModal>
  )
}

export default PaymentManagementModal;
