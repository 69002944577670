import styles from './list.module.css';
import ReactModal from 'react-modal';
import Input from '../../../components/input';
import React, { useState } from 'react';
import Button from '../../../components/button';
import { requestEntity } from '../../../transport/api';
import { dateToStr } from '../../../utils';


const DownloadButton = ({ key, periodStart, periodEnd, platformName, children, close, className }) => (
  <Button
    key={key}
    className={className}
    onClick={() => requestEntity('/export/goods', 'summary', {
      period_start: periodStart,
      period_end: periodEnd,
      platform_name: platformName,
    }).then((data) => window.open(data.url, '_blank')).finally(close)}
  >
    {children}
  </Button>
);


const ExportReportModal = ({ isOpen, close }) => {
  const getMonday = (d) => {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (
      day === 0 ? -6 : 1
    ); // adjust when day is sunday
    return dateToStr(new Date(d.setDate(diff - 1)));
  };

  const twoMonthAgo = new Date();
  twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);

  const [periodStart, setPeriodStart] = useState(getMonday(twoMonthAgo));
  const [periodEnd, setPeriodEnd] = useState(getMonday(new Date()));

  return (
    <ReactModal
      className={styles['modal-container']}
      overlayClassName={styles['modal-overlay']}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      style={{
        content: {
          height: '8em',
        },
      }}
    >
      <div className={styles.period}>
        <div>Период:</div>
        <Input
          type="date"
          value={periodStart}
          onChange={(e) => setPeriodStart(() => e.target.value)}
        />
        <div>—</div>
        <Input
          type="date"
          value={periodEnd}
          onChange={(e) => setPeriodEnd(() => e.target.value)}
        />
      </div>
      <div className={styles['export-buttons']}>
        <DownloadButton
          key="all"
          periodEnd={periodEnd}
          periodStart={periodStart}
          close={close}
          className={styles['export-all']}
        >
          Общий
        </DownloadButton>
        <DownloadButton
          key="all"
          periodEnd={periodEnd}
          periodStart={periodStart}
          platformName="ozon"
          close={close}
          className={styles['export-mp']}
        >
          <img
            key="platform-logo"
            className={styles['platform-logo']}
            src="https://st.ozone.ru/assets/favicon.ico"
            alt="platfrom"
          />
        </DownloadButton>
        <DownloadButton
          key="all"
          periodEnd={periodEnd}
          periodStart={periodStart}
          platformName="wb"
          close={close}
          className={styles['export-mp']}
        >
          <img
            key="platform-logo"
            className={styles['platform-logo']}
            src="https://www.wildberries.ru/favicon.ico"
            alt="platfrom"
          />
        </DownloadButton>
      </div>
    </ReactModal>
  );
};

export default ExportReportModal;
