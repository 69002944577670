import UncontrolledSelect from '../../../components/UncontrolledSelect';
import React from 'react';
import Button from '../../../components/button';
import ComponentContainer from '../../../components/ComponentContainer';
import { createRecord } from '../../../transport/api';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Input from '../../../components/input';


const PayPayment = ({ creditId, payment, close }) => {
  const { handleSubmit, control , register } = useForm({
    defaultValues: {
      operation_date: payment?.date,
    }
  })
  const onSubmit = async (data) => {
    createRecord(`/credits/${creditId}/payments/${payment?.id}`, data)
      .then(() => {
        toast.success('Факт оплаты успешно создан');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer title="Информация о платеже">
      <form onSubmit={handleSubmit(onSubmit)} id="payment-form">
        <Input
          label="Дата платежа"
          type="date"
          register={() =>
            register('operation_date', {
              required: true,
            })
          }
        />
        <UncontrolledSelect
          control={control}
          required
          name="physical_balance_id"
          label="Баланс списания"
          resourcePath="/physical-balances"
          loadOnFocus
          optionsColumns={{ value: 'id', label: 'name' }}
        />
      </form>
      <Button form="payment-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  )
}

export default PayPayment;
