import styles from './card.module.css';
import RecordTable from '../../../components/RecordTable';
import Input from '../../../components/input';
import Select from '../../../components/Select';
import React, { useEffect, useState } from 'react';
import { requestStatistic, requestTable } from '../../../transport/api';
import Switch from '../../../components/Switch';
import { BarShadowChart, LineChart } from '../../../components/charts';


const Realization = ({ goodsId }) => {
  const [adName, setAdName] = useState();
  const [adBillDate, setAdBillDate] = useState('');
  const [submitAdBillDate, setSubmitAdBillDate] = useState(adBillDate);
  const [goodsVariants, setGoodsVariants] = useState([]);
  const [realizationChartData, setRealizationChartData] = useState({});
  const [realizationChartView, setRealizationChartView] = useState('profit')
  const [storageData, setStorageData] = useState([])

  useEffect(() => {
    requestTable(`/goods/${goodsId}/dynamic`, [{ fieldName: 'goods_variant_ids', value: goodsVariants.join(',') }])
      .then((data) => setRealizationChartData(data?.list?.reduce(
        (acc, item) => {
          acc.dates.push(`${item.month} ${item.year}`);
          acc.sales.push(item.sales);
          acc.profit.push(item.profit);
          acc.revenue.push(item.revenue);
          return acc;
        },
        {
          dates: [],
          sales: [],
          profit: [],
          revenue: [],
        }
      )));
    requestStatistic(`/goods/${goodsId}/storages`, [
      { fieldName: 'goods_variant_ids', value: goodsVariants.join(',') },
    ]).then((data) => setStorageData(data));
  }, [goodsId, goodsVariants]);

  return (
    <div>
      <div className={styles['local-table']}>
        <Select
          value={goodsVariants}
          onChange={setGoodsVariants}
          name="goods_variant_ids"
          label='Артикулы'
          resourcePath={`/goods/${goodsId}/variants`}
          isMulti
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
        />
      </div>
      <div className={styles['chart-container']}>
        <BarShadowChart
          loading={!realizationChartData?.dates}
          title={
            <div className={styles['chart-filter']}>
              Динамика
              <Switch
                containerClassName={styles['chart-switch']}
                options={[
                  { value: 'profit', label: 'Прибыль' },
                  { value: 'sales', label: 'Количество' },
                  { value: 'revenue', label: 'Выручка' },
                ]}
                value={realizationChartView}
                onChange={(e) => setRealizationChartView(e.target.value)}
              />
            </div>
          }
          labels={realizationChartData?.dates}
          actualValues={realizationChartData?.[realizationChartView]}
          actualLabel="Текущие"
          barBorder={3}
          rightPadding={9}
          showName
        />
      </div>
      <div className={styles['local-table']}>
        <RecordTable
          title={(
            <div className={styles['title-container']}>
              <div>Рекламные кампании</div>
              <div className={styles['title-filters']}>
                <Input
                  type="date"
                  value={adBillDate}
                  onChange={(e) => setAdBillDate(() => e.target.value)}
                  options={{
                    onBlur: () => setSubmitAdBillDate(adBillDate),
                  }}
                  isFilter
                />
                <Select
                  value={adName}
                  onChange={(value) => setAdName(value)}
                  resourcePath={`/goods/${goodsId}/advertisments/names`}
                  optionsColumns={{ value: 'name', label: 'name' }}
                  label="Имя кампании"
                />
              </div>
            </div>
          )}
          columns={[
            { name: 'name', title: 'Название' },
            { name: 'mp_bill_id', title: 'Номер договора' },
            { name: 'status_desc', title: 'Статус' },
            { name: 'type_desc', title: 'Тип' },
            { name: 'volume', title: 'Затраты', type: 'number' },
            { name: 'bill_date', title: 'Дата', type: 'date' },
            { name: 'is_paid', title: 'Оплачена?', type: 'forceBool' },
          ]}
          filters={[
            { fieldName: 'bill_date', value: submitAdBillDate },
            { fieldName: 'name', value: adName },
            { fieldName: 'goods_variant_ids', value: goodsVariants.join(',') }
          ]}
          resourcePath={`/goods/${goodsId}/advertisments`}
          defaultSorting={[{ id: 'bill_date', desc: true }]}
        />
      </div>
      <div className={styles['chart-container']}>
        <LineChart
          title="Хранение"
          labels={storageData.map((storage) => storage.storage_date)}
          data={storageData.map((storage) => storage.volume)}
          formatter={(params) => {
            const storage = storageData.find((storage) => storage.storage_date === params?.[0].name);
            return `${params?.[0].name}<br/>${storage.has_operation ? '✔️' : '❌'} <bold>${storage.volume}</bold>`;
          }}
        />
      </div>

    </div>
  )
}

export default Realization;
