import React, { useEffect, useState, forwardRef } from 'react';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Item, Menu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';

import styles from './CoreTable.module.css';
import { requestTable } from '../../transport/api';
import Button from '../button';
import Skeleton from 'react-loading-skeleton';
import Cell from './Cell';
import { isFiltersEqual, isSortingEqual } from './utils';
import DeleteModal from './DeleteModal';
import { toast } from 'react-toastify';


const CoreTable = forwardRef(({
  columns,
  resourcePath,
  filters,
  onClick,
  forceUpdate,
  rowClassName,
  headersClassName,
  align = 'center',
  defaultSorting,
  containerClassName = '',
  theadClassName = '',
  skeletonCount = 20,
  onEditClick,
  getDeleteProperties,
  onLinkClick,
  restContextItems = [],
  onLoad,
}, ref) => {
  const { containerRef, tableBodyRef } = ref ? ref : {};
  const [requestOptions, setRequestOptions] = useState({
    sorting: [],
    filters: [],
    page: 1,
    update: false,
  });

  const { show: showActions } = useContextMenu({
    id: `actions-${resourcePath}`,
  });

  const showNotReady = () => toast.info('Эта функция пока не готова... Но мы уже работаем над этим 🧑‍🏭') && null;

  function handleContextMenu(event, row) {
    showActions({
      event,
      props: {
        row,
      },
    });
  }

  const [sorting, setSorting] = useState(defaultSorting);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [lastRequestOptions, setLastRequestOptions] = useState();
  const [lastForceUpdate, setLastForceUpdate] = useState();

  const [deleteConfirmationProps, setDeleteConfirmationProps] = useState({
    show: false,
    title: 'Вы уверены, что хотите удалить запись? Обратить будет операцию невозможно.',
    path: undefined,
  });

  const [page, setPage] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let shouldUpdate = true;
    if (!isFiltersEqual(requestOptions.filters, filters)) {
      setRequestOptions((options) => (
        { ...options, filters }
      ));
      setPage(1);
      shouldUpdate = false;
    }
    if (!isSortingEqual(requestOptions.sorting, sorting)) {
      setRequestOptions((options) => (
        { ...options, sorting }
      ));
      setPage(1);
      shouldUpdate = false;
    }
    if (requestOptions.page !== page) {
      setRequestOptions((options) => (
        { ...options, page }
      ));
      shouldUpdate = false;
    }
    if (
      forceUpdate === lastForceUpdate &&
      (
        lastRequestOptions?.filters ||
        lastRequestOptions?.sorting ||
        lastRequestOptions?.page
      ) &&
      isFiltersEqual(lastRequestOptions.filters, requestOptions.filters) &&
      isSortingEqual(lastRequestOptions.sorting, requestOptions.sorting) &&
      lastRequestOptions.page === requestOptions.page &&
      lastRequestOptions?.delete === deleteConfirmationProps.show
      // TODO: add edit here
    ) {
      shouldUpdate = false;
    }
    if (shouldUpdate) {
      if (resourcePath) {
        requestTable(
          resourcePath,
          requestOptions.filters,
          requestOptions.sorting,
          requestOptions.page,
        )
          .then((data) => {
            setData((prevData) => [
              ...(
                requestOptions.page > 1 ? prevData : []
              ),
              ...data?.list,
            ]);
            setTotal(() => data.total);
            setLoading(false);
            onLoad && onLoad();
          });
      } else {
        setData(() => []);
      }
      setLastForceUpdate(forceUpdate);
      setLastRequestOptions({
        sorting: requestOptions.sorting,
        filters: requestOptions.filters,
        page: requestOptions.page,
        delete: deleteConfirmationProps.show,
      });
    }
  }, [
    resourcePath,
    requestOptions.filters,
    filters,
    requestOptions.sorting,
    sorting,
    requestOptions.page,
    page,
    lastRequestOptions?.filters,
    lastRequestOptions?.sorting,
    lastRequestOptions?.page,
    lastRequestOptions?.delete,
    forceUpdate,
    lastForceUpdate,
    deleteConfirmationProps.show,
    onLoad,
  ]);

  const columnHelper = createColumnHelper();

  const preparedColumns = React.useMemo(
    () => columns.map((column) =>
      columnHelper.accessor(column.name, {
        header: column.title,
        cell: (info) => (
          <Cell
            style={column.style}
            rowData={info.row.original}
            onClick={() => onClick && onClick(column)}
            type={column.type || 'text'}
            align={column.align || (
              column.type === 'number' && 'end'
            ) || align}
          >
            {info.getValue()}
          </Cell>
        ),
        customStyle: column.customStyle,
        type: column.type,
        canSort: column.canSort === undefined ? true : column.canSort,
      }),
    ),
    [columns, align, columnHelper, onClick],
  );

  const table = useReactTable({
    data,
    columns: preparedColumns,
    getCoreRowModel: getCoreRowModel(),
    manualSortBy: false,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
  });

  return (
    <div ref={containerRef} className={`${styles.container} ${containerClassName}`}>
      <table
        className={`${styles.table} ${
          align === 'start' ? styles['table-start'] : ''
        }`}
      >
        <thead className={`${theadClassName}`}>
        {table.getHeaderGroups()
          .map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`
                    ${styles.header} 
                    ${
                    header.column.columnDef.canSort
                      ? styles['header-can-sort']
                      : ''
                  }
                    ${header.column.columnDef.customStyle || ''} 
                    ${
                    header.column.columnDef.type === 'image'
                      ? styles['column-image']
                      : ''
                  }
                    ${headersClassName}`}
                  onClick={
                    header.column.columnDef.canSort
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                >
                  <div
                    className={`${styles['header-container']} ${
                      align === 'start' ? styles['header-start'] : ''
                    }`}
                  >
                    <div>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          `${header.column.columnDef.header}`,
                          header.getContext(),
                        )}
                    </div>
                    {header.column.getIsSorted() ? (
                      <div className={styles['header-sort-direction']}>
                        {
                          {
                            asc: (
                              <img
                                src="/icons/ChevronUp.svg"
                                className={styles.icon}
                                alt={`asc`}
                              />
                            ),
                            desc: (
                              <img
                                src="/icons/ChevronDown.svg"
                                className={styles.icon}
                                alt={`desc`}
                              />
                            ),
                          }[header.column.getIsSorted()]
                        }
                      </div>
                    ) : undefined}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody ref={tableBodyRef} className={styles.body}>
        {loading ? (
          [...Array(skeletonCount)].map((_, i) => (
            <tr key={`skeleton_${i}`} className={`${styles.row} ${styles['clear-row']} ${rowClassName}`}>
              {columns.map((column, j) => (
                <td key={`skeleton_${i}_${j}`}>
                  <div className={styles['skeleton-container']}>
                    <Skeleton inline height="2em"/>
                  </div>
                </td>
              ))}
            </tr>
          ))
        ) : table.getRowModel()
          ?.rows
          .map((row) => (
            <tr
              key={row.id}
              className={`${styles.row} ${rowClassName}`}
              onClick={() => onClick && onClick(row.original)}
              onContextMenu={(event) => onEditClick || getDeleteProperties || onLinkClick || restContextItems.length > 0
                ? handleContextMenu(event, row.original)
                : undefined
              }
            >
              {row.getVisibleCells()
                .map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
            </tr>
          ))}
          <Menu id={`actions-${resourcePath}`}>
            <Item
              id="link"
              hidden={!onLinkClick}
              onClick={({ props }) => showNotReady() && onLinkClick(props.row)}>
              🔭 Перейти
            </Item>
            <Item
              id="edit"
              hidden={!onEditClick}
              onClick={({ props }) => showNotReady() && onEditClick(props.row)}
            >
              ✏️ Изменить
            </Item>
            <Item
              id="delete"
              hidden={!getDeleteProperties}
              onClick={({ props }) => {
                const deleteProperties = getDeleteProperties(props.row);
                setDeleteConfirmationProps((prevState) => (
                  {
                    show: true,
                    path: deleteProperties.path,
                    title: deleteProperties.title || prevState.title,
                    callback: deleteProperties.callback
                  }
                ));
              }}
            >
              🗑 Удалить
            </Item>
            {restContextItems.map((item) => (
              <Item id={item.id} onClick={({ props }) => item.onClick(props.row)}>
                {item.label}
              </Item>
            ))}
          </Menu>
        </tbody>
      </table>
      {data.length < total ? (
        <div className={styles['load-more-container']}>
          <Button onClick={() => setPage(() => requestOptions.page + 1)}>
            Загрузить ещё
          </Button>
        </div>
      ) : undefined}
      <DeleteModal
        {...deleteConfirmationProps}
        close={() => setDeleteConfirmationProps((prevState) => (
          { ...prevState, show: false }
        ))}
      />
    </div>
  );
});

export default CoreTable;
