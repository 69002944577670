import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { makeTitle, useFilters } from '../../utils';

import AppContainer from './../../components/app-container/index';
import AppTable from './../../components/AppTable/index';
import EditModal from '../../components/EditModal';
import AddTitle from '../../components/AddTitle';
import Select from '../../components/Select';

import ShipmentCreateForm from './ShipmentCreateForm';
import { useSearchParams } from 'react-router-dom';

const Shipments = () => {
  makeTitle('Поставки');

  const { filters, setFilter } = useFilters()

  useEffect(() => {
    const message = sessionStorage.getItem('createShipment');
    if (message) {
      toast.success(message);
      sessionStorage.removeItem('createShipment');
    }
  });

  const [newShipment, toggleNewShipment] = useState(false);

  return (
    <AppContainer
      title={<AddTitle text='Поставки' onAddClick={() => toggleNewShipment(true)} />}
      filters={[
        <Select
          value={filters.get('shipmentId')}
          onChange={(value) => setFilter('shipmentId', value)}
          resourcePath="/shipments/mp-ids"
          optionsColumns={{ value: 'mp_id', label: 'mp_display_id' }}
          label="Поставка"
        />,
        <Select
          value={filters.get('goodsVariantId')}
          onChange={(value) => setFilter('goodsVariantId', value)}
          resourcePath="/goods-variants"
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
          label="Артикул"
          filters={[{ fieldName: 'exclude_same_variants', value: true }]}
        />,
        <Select
          value={filters.get('warehouseId')}
          onChange={(value) => setFilter('warehouseId', value)}
          resourcePath="/warehouses"
          optionsColumns={{ value: 'id', label: 'name' }}
          label="Склад"
        />,
      ]}
    >
      <AppTable
        columns={[
          { name: 'mp_display_id', title: 'Идентификатор МП' },
          { name: 'vendor_code', title: 'Артикул', canSort: false },
          { name: 'platform_name', title: 'Платформа', canSort: false },  // TODO: replace with mp icons
          { name: 'warehouse_name', title: 'Склад', canSort: false },
          { name: 'volume', title: 'Количество', style: 'rectangle-blue' },
          { name: 'remaining_volume', title: 'Количество', style: 'rectangle-blue' },
          { name: 'shipment_date', title: 'Дата поставки', type: 'date' },
          {
            name: 'is_shipment_in_supply',
            title: 'Все товары привязаны к партии?',
            type: 'forceBool',
          },
        ]}
        resourcePath="/shipments"
        filters={[
          { fieldName: 'goods_variant_id', value: filters.get('goodsVariantId') },
          { fieldName: 'mp_id', value: filters.get('shipmentId') },
          { fieldName: 'warehouse_id', value: filters.get('warehouseId') },
        ]}
        defaultSorting={[{ id: 'shipment_date', desc: true }]}
      />
      <EditModal
        isOpen={newShipment}
        close={() => toggleNewShipment(false)}
      >
        <ShipmentCreateForm />
      </EditModal>
    </AppContainer>
  );
};

export default Shipments;
