import { requestStatistic, updateRecord } from '../../../transport/api';
import ComponentContainer from '../../../components/ComponentContainer';
import styles from './card.module.css';
import Button from '../../../components/button';
import React, { useEffect, useState } from 'react';
import Select from '../../../components/Select';
import { toast } from 'react-toastify';


const SameVariantsForm = ({ goodsId, close }) => {
  const [goodsVariants, setGoodsVariants] = useState([]);
  const [sameAsMapping, setSameAsMapping] = useState({});

  useEffect(() => {
    requestStatistic(`/goods/${goodsId}/variants`).then((data) => {
      setGoodsVariants(data.list);
      setSameAsMapping(Object.fromEntries(data.list.map((variant) => [`${variant.id}`, variant.same_as_id])));
    });
  }, [goodsId]);

  return Object.keys(sameAsMapping).length > 0 && goodsVariants.length > 0 ? (
    <ComponentContainer title="Привязка артикулов" className={styles['link-container']}>
      <div id="variants-form">
        {goodsVariants.filter(variant => variant.platform_name !== 'wb').map(variant => (
          <div key={`goods_variant_${variant.id}_container`}>
            <div className={styles['same-select-label']}>
              <div>
                {variant.vendor_code}
              </div>
              <img
                key="platform-logo"
                className={styles['platform-logo']}
                src={
                  variant.platform_name === 'wb' ? 'https://www.wildberries.ru/favicon.ico' :
                  variant.platform_name === 'ozon' ? 'https://st.ozone.ru/assets/favicon.ico' : ''
                }
                alt="platfrom"
              />
            </div>
            <Select
              name={`goods_variant_${variant.id}`}
              label=""
              customOptions={
                goodsVariants
                  .filter(v => !sameAsMapping[`${v.id}`])
                  .map(v => ({ value: v.id, label: v.vendor_code, color: v.platform_name === 'wb' ? '#a02af1' : v.platform_name === 'ozon' ? '#005bff' : 'black' }))
              }
              value={sameAsMapping[`${variant.id}`]}
              onChange={v => setSameAsMapping({ ...sameAsMapping, [`${variant.id}`]: v })}
            />
          </div>
        ))}
      </div>
      <Button
        onClick={() => updateRecord(
          '/goods-variants/same-as',
          Object.entries(sameAsMapping)
            .map(([id, same_as_id]) => ({ id: parseInt(id), same_as_id }))
        )
          .then(() => toast.success('Артикулы успешно связаны') && close())
          .catch((error) => toast.error(error.message))
        }
      >
        Сохранить
      </Button>
    </ComponentContainer>
  ) : undefined;
}

export default SameVariantsForm;