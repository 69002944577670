import React, { forwardRef } from 'react';
import ComponentContainer from '../ComponentContainer';
import CoreTable from '../CoreTable';

import styles from './RecordTable.module.css';

const RecordTable = forwardRef(({
  columns,
  resourcePath,
  filters,
  onClick,
  forceUpdate,
  title,
  defaultSorting = [],
  containerClassName = '',
  onEditClick,
  getDeleteProperties,
  onLinkClick,
  componentContainer,
  restContextItems
}, ref) => {
  return (
    <ComponentContainer title={title} className={`${styles.container} ${componentContainer || ''}`}>
      <CoreTable
        columns={columns}
        resourcePath={resourcePath}
        filters={filters}
        onClick={onClick}
        forceUpdate={forceUpdate}
        containerClassName={`${styles['table-container']} ${containerClassName || styles.standard}`}
        rowClassName={styles.row}
        headersClassName={styles.headers}
        align="start"
        defaultSorting={defaultSorting}
        theadClassName={styles.thead}
        skeletonCount={7}
        onEditClick={onEditClick}
        getDeleteProperties={getDeleteProperties}
        onLinkClick={onLinkClick}
        restContextItems={restContextItems}
        ref={ref}
      />
    </ComponentContainer>
  );
})

export default RecordTable;
