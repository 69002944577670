import { graphic } from 'echarts'

export const buildOptions = (labels, values, startColor, endColor, itemsColor, formatter = undefined, left = 5, startValue = 0) => (
  {
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: labels,
    show: true,
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    offset: 5,
  },
  yAxis: {
    type: 'value',
    show: true,
    offset: 10,
    minInterval: 5,
    min: startValue
  },
  grid: {
    top: '10%',
    right: '2%',
    left: `${left}%`,
    bottom: '10%',
    // height: '100%',
  },
  tooltip: {
    position: 'top',
    trigger: 'axis',
    formatter: formatter,
  },
  textStyle: {
    fontFamily: 'Nunito'
  },
  series: [
    {
      data: values,
      type: 'line',
      smooth: true,
      // symbol: 'circle',
      showSymbol: false,
      symbolSize: 12,
      itemStyle: {
        borderWidth: 4,
        borderColor: itemsColor,
        color: 'var(--modal-background)',
      },
      lineStyle: {
        width: 4,
        color: new graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: startColor,
          },
          {
            offset: 1,
            color: endColor,
          }]
        ),
        shadowBlur: 4,
        shadowColor: 'rgba(96, 91, 255, 0.17)',
        shadowOffsetY: 4,
      },
      areaStyle: {
        color: new graphic.LinearGradient(0, 0, 1, 0, [
          {
            offset: 0,
            color: `${startColor}0F`
          },
          {
            offset: 1,
            color: `${endColor}0F`
          }])
      },
    }
  ],
})
