export const buildOptions = (data, title, borderColor, textColor, displayPercent = false) => {
  function circle(param) {
    return `<div style=
      'height: 10px;width: 10px;
      background-color: ${param.color};
      border-radius: 50%;
      display: inline-block;'></div>`
  }
  let chartSumm = 0;
  if (displayPercent) {
    chartSumm = data.reduce((n, { value }) => n + value, 0);
  }
  return {
    tooltip: {
      trigger: 'item',
      formatter: (param) => {
        return `${param.seriesName}
          &nbsp;<br />${circle(param)} ${param.data.name} &nbsp; <b>${param.data.value} 
          ${displayPercent ? '(' + Math.round(param.data.value / chartSumm * 100) + '%)' : ''}</b>`;
      }
    },
    legend: data.length <= 4 ? {
      bottom: 'bottom',
      left: 'center',
      textStyle: {
        color: textColor
      },
    } : undefined,
    textStyle: {
      fontFamily: 'Nunito'
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: ['30%', '60%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 5,
          borderColor: borderColor,
          borderWidth: 2
        },
        label: {
          show: false,
        },
        emphasis: {
        },
        labelLine: {
          show: false
        },
        data: data.map((item) => ({ name: item.name, value: Math.round(item.value) })),
      }
    ]
  }
}