import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/button';
import ComponentContainer from '../../components/ComponentContainer';
import Input from '../../components/input';
import UncontrolledSelect from '../../components/UncontrolledSelect';
import { createRecord } from '../../transport/api';
import { getDate } from '../../utils';

const InformationCreateForm = () => {
  const navigate = useNavigate();

  const [volume, setVolume] = useState(0);
  const [hiddenVolume, setHiddenVolume] = useState(0);

  const { register, handleSubmit, control } = useForm({
    defaultValues: {
      supply_date: getDate(),
      use_in_turnover: false,
    }
  });

  const onSubmit = async (data) => {
    createRecord('/supplies/new', data)
      .then((responseData) => {
        toast.success('Партия успешно создана');
        navigate(`/supplies/${responseData.supply_id}`);
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer title="Основная информация">
      <form onSubmit={handleSubmit(onSubmit)} id="supply-form">
        <Input
          label="Объём"
          type="number"
          options={{ min: 0 }}
          register={() =>
            register('volume', {
              required: true,
              onChange: (e) => setVolume(e.target.value)
            })
          }
        />
        <Input
          label="Озон"
          type="number"
          value={hiddenVolume}
          options={{ min: 0, max: volume }}
          register={() => register('hidden_volume', {
            onChange: (e) => setHiddenVolume(e.target.value)
          })}
        />
        <Input
          label="Дата"
          type="datetime-local"
          register={() =>
            register('supply_date', { required: true })
          }
        />
        <UncontrolledSelect
          control={control}
          name="goods_variant_id"
          label="Артикул продавца"
          resourcePath="/goods-variants"
          loadOnFocus
          optionsColumns={{ value: 'id', label: 'vendor_code' }}
          filters={[{ fieldName: 'exclude_same_variants', value: true }]}
        />
      </form>
      <Button form="supply-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default InformationCreateForm;
