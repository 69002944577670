import { useForm } from 'react-hook-form';
import { callAction, requestEntity, updateRecord } from '../../../transport/api';
import { makeTitle } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';
import React, { useState } from 'react';
import AppContainer from '../../../components/app-container';
import Alert from '../../../components/Alert';

import styles from './card.module.css';
import RecordTable from '../../../components/RecordTable';
import Switch from '../../../components/Switch';


const Seller = () => {
  const { sellerId } = useParams();
  const navigate = useNavigate();
  const [isWbEnabled, toggleWbEnabled] = useState(false);
  const [isOzonEnabled, toggleOzonEnabled] = useState(false);
  const [hasInitializationError, toggleInitializationError] = useState(false);

  const { register, handleSubmit, getValues, setValue } = useForm({
    defaultValues: async () => requestEntity('/sellers', sellerId)
      .then((data) => {
        toggleWbEnabled(data.is_wb_enabled);
        toggleOzonEnabled(data.is_ozon_enabled);
        toggleInitializationError(data.has_initialization_error);
        return { ...data, id: data.id || sellerId, is_wb_enabled: `wb_${data.is_wb_enabled}`, is_ozon_enabled: `ozon_${data.is_ozon_enabled}` };
      })
      .catch((_) => navigate(-1, { replace: true })),
  });

  makeTitle(getValues('name'));

  const onSubmit = async (data) => {
    updateRecord(`/sellers/${sellerId}`, Object.fromEntries(
      Object.entries(data)
        .filter(([_, v]) => !!v)
        .map(([k, v]) => [k, k.endsWith('_enabled') ? v.includes('_true') : v])
    ))
      .then(() => toast.success('Данные успешно обновлены'))
      .catch((error) => toast.error(error.message));
  };

  return (
    <AppContainer title={getValues('name')}>
      <div className={styles.container}>
        <ComponentContainer title="Основная информация">
          <form onSubmit={handleSubmit(onSubmit)} id="seller-form">
            <Input
              label="Название"
              register={() =>
                register('name', {})
              }
            />
            <Switch
              label="WB"
              options={[
                { value: 'wb_true', label: 'Активно' },
                { value: 'wb_false', label: 'Неактивно' },
              ]}
              register={() =>
                register('is_wb_enabled', {
                  onChange: (e) => {
                    toggleWbEnabled(e.target.value === 'wb_true');
                    setValue('is_wb_enabled', e.target.value);
                  },
                })
              }
            />
            {isWbEnabled ?
                (
                  <Alert>
                    Api-ключ должен быть с полномочиями: Контент, Статистика, Аналитика и Продвижение
                    <div className={styles.bold}>(всё в режиме только для чтения!)</div>
                  </Alert>
                ) : undefined
            }
            {isWbEnabled ? (
              <Input
                label="Api-ключ"
                type="password"
                register={() => register('wb_api_key', {})}
              />
            ): undefined}
            <Switch
              label="Ozon"
              options={[
                { value: 'ozon_true', label: 'Активно' },
                { value: 'ozon_false', label: 'Неактивно' },
              ]}
              register={() =>
                register('is_ozon_enabled', {
                  onChange: (e) => {
                    toggleOzonEnabled(e.target.value === 'ozon_true')
                    setValue('is_ozon_enabled', e.target.value);
                  },
                })
              }
            />
            {isOzonEnabled ? (
              <Alert>
                Для корректной работы необходимо указать <kbd>API-ключ</kbd>, <kbd>Client id</kbd> для <a href="https://seller.ozon.ru/app/settings/api-keys">Seller API</a>
                {' '}и <kbd>Client secret</kbd> и <kbd>Client id</kbd> для {' '}
                <a href="https://seller.ozon.ru/app/settings/api-keys?currentTab=performanceApi">Performance API</a>.
              </Alert>
            ) : undefined}
            {isOzonEnabled ? (
              <Input
                label="Api-ключ"
                type="password"
                register={() => register('ozon_api_key', {})}
              />
            ): undefined}
            {isOzonEnabled ? (
              <Input
                label="Client id"
                type="password"
                register={() => register('ozon_client_id', {})}
              />
            ) : undefined}
            {isOzonEnabled ? (
              <Input
                label="Client secret (Реклама)"
                type="password"
                register={() => register('ozon_advertising_secret', {})}
              />
            ) : undefined}
            {isOzonEnabled ? (
              <Input
                label="Client id (Реклама)"
                type="password"
                register={() => register('ozon_advertising_client_id', {})}
              />
            ) : undefined}
          </form>
          <div className={styles['buttons-container']}>
            <Button form="seller-form" type="submit">Сохранить</Button>
            {hasInitializationError ? (
              <Button
                onClick={() => callAction(`/sellers/${sellerId}/reinitialize`)}
                key="reinit"
              >
                Инициализировать повторно
              </Button>
            ) : undefined}
          </div>
        </ComponentContainer>
        <RecordTable
          title="Интеграция"
          containerClassName={styles['table-container']}
          columns={[
            { name: 'name', title: 'Название' },
            { name: 'last_date', title: 'Дата последней успешной интеграции', type: 'datetime' },
          ]}
          resourcePath={`/sellers/${sellerId}/api-resources`}
        />
      </div>
    </AppContainer>
  );
}

export default Seller;