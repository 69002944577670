import React, { useState } from 'react';
import styles from './MoneyOperations.module.css';
import ReactModal from 'react-modal';
import Input from '../../components/input';
import Button from '../../components/button';
import { requestEntity } from '../../transport/api';
import dayjs from 'dayjs';


const DownloadButton = ({ key, periodStart, periodEnd, children, close, className, path }) => (
  <Button
    key={key}
    className={className}
    onClick={() => requestEntity('/export/money-operations', path, {
      period_start: periodStart,
      period_end: periodEnd,
    }).then((data) => window.open(data.url, '_blank')).finally(close)}
  >
    {children}
  </Button>
);


const ExportReportModal = ({ isOpen, close }) => {
  const [periodStart, setPeriodStart] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [periodEnd, setPeriodEnd] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));

  return (
    <ReactModal
      className={styles['modal-container']}
      overlayClassName={styles['modal-overlay']}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={close}
      style={{
        content: {
          height: '8em',
        },
      }}
    >
      <div className={styles.period}>
        <div>Период:</div>
        <Input
          type="date"
          value={periodStart}
          onChange={(e) => setPeriodStart(() => e.target.value)}
        />
        <div>—</div>
        <Input
          type="date"
          value={periodEnd}
          onChange={(e) => setPeriodEnd(() => e.target.value)}
        />
      </div>
      <div className={styles['export-buttons']}>
        <DownloadButton
          key="grouped"
          path="grouped"
          periodEnd={periodEnd}
          periodStart={periodStart}
          close={close}
          className={styles['export-all']}
        >
          Сгруппированный
        </DownloadButton>
        <DownloadButton
          key="extended"
          path="extended"
          periodEnd={periodEnd}
          periodStart={periodStart}
          close={close}
          className={styles['export-all']}
        >
          С детализацией общих операций
        </DownloadButton>
      </div>
    </ReactModal>
  );
};

export default ExportReportModal;
