export const buildOptions = (
  labels,
  actualValues,
  oldValues,
  actualColor,
  actualLabel,
  oldLabel,
  barBorder,
  rightPadding,
  showName,
  secondaryColor
) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      show: false,
      type: 'none',
    },
    formatter: (params) => {
      const name = showName ? `<bold>${params[0].name}</bold><br />` : ''
      const actual = `
        ${name}
        ${params[0].marker} ${params[0].seriesName}: ${params[0].value}`
      return params?.[1] ? `${actual}<br />
        ${params[1].marker} ${params[1].seriesName}: ${params[1].value}
      ` : actual
    },
  },
  textStyle: {
    fontFamily: 'Nunito'
  },
  grid: {
    left: '1%',
    right: '1%',
    bottom: '3%',
    containLabel: true
  },
  yAxis: {
    type: 'value'
  },
  xAxis: {
    type: 'category',
    data: labels,
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      padding: [0, rightPadding, 0, 0]
    }
  },
  series: [
    {
      zlevel: 1,
      barWidth: '60%',
      name: actualLabel,
      type: 'bar',
      itemStyle: {
        borderRadius: [barBorder, barBorder, 0, 0],
        color: actualColor,
      },
      data: actualValues,
    },
    {
      barGap: '-117.5%',
      name: oldLabel,
      type: 'bar',
      itemStyle: {
        borderRadius: [barBorder, barBorder, 0, 0],
        color: secondaryColor,
      },
      emphasis: {
        itemStyle: {
          color: secondaryColor,
        }
      },
      barWidth: '80%',
      data: oldValues,
    },


  ]
})
