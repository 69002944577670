import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { requestEntity, requestStatistic } from '../../../transport/api';

import AppContainer from '../../../components/app-container';
import AppTitle from '../../../components/AppTitle';
import Button from '../../../components/button';
import EditModal from '../../../components/EditModal';
import EmojiButton from '../../../components/EmojiButton';
import Operation from '../../operations/Operation';
import PositionalModal from '../../../components/PositionalModal';
import Switch from '../../../components/Switch';

import GoodsEdit from './GoodsEdit';
import Balance from './Balance';

import { makeTitle } from '../../../utils';

import styles from './card.module.css';
import LinkVariantsForm from './LinkVariantsForm';
import Cash from './Cash';
import Realization from './Realization';
import SameVariantsForm from './SameVariantsForm';

const GoodsCard = () => {
  const { goodsId } = useParams();
  const [data, setData] = useState({});
  const [view, setView] = useState('cash');
  const [isGoodsEdit, toggleGoodsEdit] = useState(false);
  const [isSharedEdit, toggleSharedEdit] = useState(false);

  const [newOperation, setNewOperation] = useState('');
  const [isOperationModalOpen, toggleOperationModalOpen] = useState(false);
  const [isLinkVariantsOpen, toggleLinkVariantsOpen] = useState(false);
  const [isSameModalOpen, toggleSameModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [goodsBalanceName, setGoodsBalanceName] = useState();
  const [masterBalanceName, setMasterBalanceName] = useState();

  const operationButton = useRef()

  makeTitle(`Проект ${data?.name}`);

  useEffect(() => {
    requestEntity('/goods/value-list', goodsId).then((data) => {
      setData(data);
      setLoading(false);
    });
    requestStatistic(`/goods/${goodsId}/default-balances`).then((data) => {
      setMasterBalanceName(data?.master_balance_name);
      setGoodsBalanceName(data?.goods_balance_name);
    })
  }, []);

  return (
    <AppContainer
      title={
        <div className={styles['button-container']}>
          <AppTitle loading={loading}>Проект {data?.name}</AppTitle>
          <EmojiButton
            disabled={loading}
            onClick={() => toggleGoodsEdit(true)}
            className={styles.button}
            emoji="✏️"
          />
          <EmojiButton
            disabled={loading}
            onClick={() => toggleOperationModalOpen(true)}
            emoji="💸"
            className={styles.button}
            ref={operationButton}
          />
          <EmojiButton
            disabled={loading}
            onClick={() => toggleLinkVariantsOpen(true)}
            emoji="🔗"
            title="Привязать артикулы"
            className={styles.button}
            ref={operationButton}
          />
          <EmojiButton
            disabled={loading}
            onClick={() => toggleSameModalOpen(true)}
            emoji="🧲"
            title="Сопоставить артикулы"
            className={styles.button}
            ref={operationButton}
          />
        </div>
      }
      filters={[
        <Switch
          disabled={loading}
          value={view}
          onChange={(e) => {
            setView(e.target.value);
          }}
          isFilter
          options={[
            { value: 'cash', label: 'Финансы' },
            { value: 'realization', label: 'Реализация' },
          ]}
        />,
      ]}
    >
      {view === 'cash' ? (
        <Cash
          goodsId={goodsId}
          isGoodsEdit={isGoodsEdit}
          toggleGoodsEdit={toggleGoodsEdit}
          isLinkVariantsOpen={isLinkVariantsOpen}
        />
      ): undefined}
      {view === 'realization' ? (
        <Realization
          goodsId={goodsId}
        />
      ): undefined}
      <EditModal close={() => {
        toggleGoodsEdit(false);
        toggleSharedEdit(false);
      }} isOpen={isGoodsEdit}>
        <GoodsEdit
          close={() => {
            toggleGoodsEdit(false);
            toggleSharedEdit(false);
          }}
          isOpen={isGoodsEdit}
          goodsId={goodsId}
          initial_date={data?.initial_date}
          isSharedEdit={isSharedEdit}
        />
      </EditModal>
      <EditModal
        close={() => setNewOperation(undefined)}
        isOpen={!!newOperation}
      >
        <Operation
          close={() => setNewOperation(undefined)}
          newOperation={newOperation}
          defaultFromBalanceName={{
            PAYMENT: goodsBalanceName,
            TAX: goodsBalanceName,
            OUTPUT: goodsBalanceName,
            INNER_TRANSFER: goodsBalanceName,
            INPUT: null,
            DEPOSIT: masterBalanceName,
            WITHDRAW: goodsBalanceName,
          }[newOperation]}
          defaultToBalanceName={{
            PAYMENT: null,
            TAX: null,
            OUTPUT: null,
            INNER_TRANSFER: masterBalanceName,
            INPUT: goodsBalanceName,
            DEPOSIT: goodsBalanceName,
            WITHDRAW: masterBalanceName,
          }[newOperation]}
        />
      </EditModal>
      <PositionalModal
        positionFrom={{ left: operationButton.current?.offsetLeft, top: operationButton.current?.offsetTop }}
        isOpen={isOperationModalOpen}
        height="19em"
        width="12.5em"
        close={() => toggleOperationModalOpen(false)}
      >
        {[
          ['PAYMENT', 'Оплата партии'],
          ['OUTPUT', 'Новый расход'],
          ['TAX', 'Оплата налога'],
          ['INNER_TRANSFER', 'Перевод'],
          ['INPUT', 'Внесение баланса'],
          // ['DEPOSIT', 'Ввод вложений'],
          // ['WITHDRAW', 'Вывод вложений'],
          ['IS_USED_IN_SHARED', 'Участие в общих операциях'],
        ].map(([operation, name]) => (
          <Button
            className={styles['modal-button']}
            theme="borderless"
            key={operation}
            onClick={() => {
              if (operation === 'IS_USED_IN_SHARED') {
                toggleGoodsEdit(true);
                toggleSharedEdit(true);
              } else {
                setNewOperation(operation);
              }
              toggleOperationModalOpen(false);
            }}
          >
            {name}
          </Button>
        ))}
      </PositionalModal>
      <EditModal isOpen={isLinkVariantsOpen} close={() => toggleLinkVariantsOpen(false)}>
        <LinkVariantsForm close={() => toggleLinkVariantsOpen(false)} goodsId={goodsId} />
      </EditModal>
      <EditModal isOpen={isSameModalOpen} close={() => toggleSameModalOpen(false)}>
        <SameVariantsForm close={() => toggleSameModalOpen(false)} goodsId={goodsId} />
      </EditModal>
    </AppContainer>
  );
};

export default GoodsCard;
