import { useForm } from 'react-hook-form';
import { createRecord, updateRecord } from '../../../transport/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ComponentContainer from '../../../components/ComponentContainer';
import Input from '../../../components/input';
import Button from '../../../components/button';
import React from 'react';
import Alert from '../../../components/Alert';
import styles from './investor.module.css';
import UncontrolledSelect from '../../../components/UncontrolledSelect';


const ChangeInvestor = ({ close, investorData }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: investorData ?? {}
  });
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    if (!investorData) {
      createRecord('/investors', data)
        .then((responseData) => {
          toast.success('Инвестор успешно создан');
          navigate(`/investors/${responseData.id}`);
        })
        .catch((error) => toast.error(error.message));
    } else {
      updateRecord(`/investors/${investorData.id}`, data)
        .then(() => {
          toast.success('Инвестор успешно обновлён');
          close();
        })
        .catch((error) => toast.error(error.message));
    }
  };

  return (
    <ComponentContainer title="Основная информация">
      <form onSubmit={handleSubmit(onSubmit)} id="investor-form">
        <Input
          label="Название"
          register={() => register('name', { required: true })}
        />
        <Alert>
          <div className={styles.bold}>Будьте внимательны с параметром "процент с прибыли"!</div>
          Если у Вас есть партнёр, с которым Вы делите прибыль в долях, соответствующих вложенным средствам, нужно
          указать 100%, но если как премию за Ваш труд Вы забираете, например 20% его прибыли, то тогда необходимо
          написать здесь 80%.
        </Alert>
        <Input
          label="Процент с прибыли"
          type="number"
          options={{ min: 0, max: 100 }}
          register={() => register('percent', { required: true })}
        />
        {investorData ? (
          <UncontrolledSelect
            control={control}
            name="status"
            label="Статус"
            resourcePath="/investors/statuses"
            optionsColumns={{ value: 'value', label: 'label' }}
          />
        ) : undefined}
        {investorData ? (<UncontrolledSelect
          control={control}
          name="user_id"
          label="Пользователь"
          resourcePath={`/investors/${investorData.id}/users`}
          optionsColumns={{ value: 'id', label: 'fullname' }}
        />) : undefined}
      </form>
      <Button form="investor-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
}

export default ChangeInvestor;
