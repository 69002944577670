import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import AuthLayout from './components/AuthLayout';
import StandardDashboard from './routes/StandardDashboard';
import Login from './routes/login';
import Logout from './routes/logout';
import SystemLayout from './components/SystemLayout';
import MoneyOperations from './routes/operations';
import Supplies from './routes/supplies';
import Balance from './routes/balance';
import Users from './routes/users';

import Groups from './routes/groups/index';
import Group from './routes/group/index';
import { Goods, GoodsCard } from './routes/goods';
import AuditRecords from './routes/audit';
import Shipments from './routes/shipments';
import Supply from './routes/supply';
import NoConnection from './routes/NoConnection';
import {SkeletonTheme} from "react-loading-skeleton";
import { Turnovers, GoodsVariantTurnoverCard, WarehouseTurnoverCard } from './routes/tunrovers';
import { useEffect, useState } from 'react';
import ThemeContext from './ThemeContext';
import ReactModal from 'react-modal';
import { Seller, Sellers } from './routes/sellers';
import Cookies from 'js-cookie';
import InvestorDashboard from './routes/InvestorDashboard';
import LoanFunds, { Investor, Credit } from './routes/LoanFunds';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';


function App() {
  ReactModal.setAppElement(document.getElementById('root'));

  const [theme, setTheme] = useState(localStorage.getItem('theme') || (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'));
  const [role, setRole] = useState(Cookies.get('role'))

  useEffect(() => {
    localStorage.setItem('theme', theme);
    document.documentElement.dataset.theme = theme;
  }, [theme]);

  const routes = [
    {
      // ! Обрати внимание, что не может быть два элемента с одинаковым path у одной роли !
      path: '/',
      label: 'Дашборд',
      icon: '/icons/Category.svg',
      element: <StandardDashboard />,
      roles: ['STANDARD', 'ADMIN'],
    },
    {
      // ! Обрати внимание, что не может быть два элемента с одинаковым path у одной роли !
      path: '/',
      label: 'Дашборд',
      icon: '/icons/Category.svg',
      element: <InvestorDashboard />,
      roles: ['INVESTOR'],
    },
    {
      path: '/balance',
      label: 'Баланс',
      icon: '/icons/Wallet.svg',
      element: <Balance />,
      roles: ['STANDARD'],
    },
    {
      path: '/goods',
      label: 'Проекты',
      icon: '/icons/Ticket Star.svg',
      element: <Goods />,
      roles: ['STANDARD'],
    },
    {
      path: '/operations',
      label: 'Денежные операции',
      icon: '/icons/Swap.svg',
      element: <MoneyOperations />,
      roles: ['STANDARD'],
    },
    {
      path: '/supplies',
      label: 'Себестоимость',
      icon: '/icons/Download.svg',
      element: <Supplies />,
      roles: ['STANDARD'],
    },
    {
      path: '/shipments',
      label: 'Поставки',
      icon: '/icons/Upload.svg',
      element: <Shipments />,
      roles: ['STANDARD'],
    },
    {
      path: '/turnover',
      label: 'Оборачиваемость',
      icon: '/icons/Buy.svg',
      element: <Turnovers />,
      roles: ['STANDARD'],
    },
    {
      path: '/users',
      label: 'Пользователи',
      icon: '/icons/Two Users.svg',
      element: <Users />,
      roles: ['ADMIN'],
    },
    {
      path: '/groups',
      label: 'Группы',
      icon: '/icons/People.svg',
      element: <Groups />,
      roles: ['ADMIN'],
    },
    {
      path: '/audit',
      label: 'Аудит пользователей',
      element: <AuditRecords />,
      icon: '/icons/Audit.svg',
      roles: ['STANDARD', 'ADMIN'],
    },
    {
      path: '/sellers',
      label: 'Кабинеты',
      element: <Sellers />,
      icon: '/icons/Two Users.svg',
      roles: ['STANDARD'],
    },
    {
      path: '/loan-funds',
      label: 'Заёмные средства',
      element: <LoanFunds />,
      icon: '/icons/Discount.svg',
      roles: ['STANDARD'],
    },
    {
      path: '/groups/:groupId',
      element: <Group />,
      roles: ['ADMIN'],
    },
    {
      path: '/goods/:goodsId',
      element: <GoodsCard />,
      roles: ['STANDARD'],
    },
    {
      path: '/supplies/:supplyId',
      element: <Supply />,
      roles: ['STANDARD'],
    },
    {
      path: '/turnover/:goodsVariantId',
      element: <GoodsVariantTurnoverCard />,
      roles: ['STANDARD'],
    },
    {
      path: '/warehouse-turnover/:warehouseId',
      element: <WarehouseTurnoverCard />,
      roles: ['STANDARD'],
    },
    {
      path: '/sellers/:sellerId',
      element: <Seller />,
      roles: ['STANDARD'],
    },
    {
      path: '/investors/:investorId',
      element: <Investor />,
      roles: ['STANDARD'],
    },
    {
      path: '/credits/:creditId',
      element: <Credit />,
      roles: ['STANDARD'],
    }
  ]
  return (
    <div className={`default ${theme}`}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
      <SkeletonTheme
        borderRadius="10px"
        baseColor="var(--skeleton-base-color)"
        highlightColor="var(--skeleton-highlight-color)"
      >
        <BrowserRouter>
          <Routes>
            <Route element={<AuthLayout />}>
              <Route element={<SystemLayout routes={routes} setRole={setRole} />}>
                {routes
                  .filter(({ roles }) => roles.includes(role))
                  .map(({path, element}) => <Route key={path} path={path} element={element} />)}
              </Route>
            </Route>
            <Route
              path="/login"
              element={<Login setRole={setRole} />}
            />
            <Route
              path="/logout"
              element={<Logout />}
            />
            <Route
              path="/no-connection"
              element={<NoConnection />}
            />
            <Route
              path='*'
              element={<Login setRole={setRole} />}
            />
          </Routes>
        </BrowserRouter>
      </SkeletonTheme>
      <ToastContainer
        position="top-right"
        className='toast'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
