import React from 'react';
import ReactECharts from 'echarts-for-react';
import { buildOptions } from './options';

import ComponentContainer from '../../ComponentContainer';

const LineChart = ({
  labels,
  data,
  title,
  startColor = '#5BC4FF',
  endColor = '#FF5BEF',
  itemsColor = '#AE8FF7',
  formatter = undefined,
  containerClassName = '',
  leftOffset = 5,
  startValue = 0
}) => {
  return (
    <ComponentContainer title={title} className={containerClassName}>
      <ReactECharts
        notMerge={true}
        lazyUpdate={true}
        style={{ height: '23em' }}
        option={buildOptions(labels, data, startColor, endColor, itemsColor, formatter, leftOffset, startValue)}
      />
    </ComponentContainer>
  );
};

export default LineChart;
