import React from 'react';
import Button from '../../../components/button';
import ComponentContainer from '../../../components/ComponentContainer';
import { createRecord, requestEntity, updateRecord } from '../../../transport/api';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import Input from '../../../components/input';


const ChangePayment = ({ creditId, paymentId, close }) => {
  const { handleSubmit , register } = useForm({
    defaultValues: paymentId ? async () => requestEntity(`/credits/${creditId}/payments`, paymentId) : {}
  })
  const onSubmit = async (data) => {
    if (paymentId) {
      updateRecord(`/credits/${creditId}/payments/${paymentId}`, data)
        .then(() => {
          toast.success('Платёж успешно обновлён');
          close();
        })
        .catch((error) => toast.error(error.message));
    } else {
      createRecord(`/credits/${creditId}/payments`, data)
        .then(() => {
          toast.success('Платёж успешно создан');
          close();
        })
        .catch((error) => toast.error(error.message));
    }
  };

  return (
    <ComponentContainer title="Информация о платеже">
      <form onSubmit={handleSubmit(onSubmit)} id="change-payment-form">
        <Input
          label="Дата платежа"
          type="date"
          register={() =>
            register('payment_date', {
              required: true,
            })
          }
        />
        <Input
          label="Проценты"
          type="number"
          step="0.01"
          register={() =>
            register('interest_volume', {
              required: true,
            })
          }
        />
        <Input
          label="Тело"
          type="number"
          step="0.01"
          register={() =>
            register('body_volume', {
              required: true,
            })
          }
        />
      </form>
      <Button form="change-payment-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  )
}

export default ChangePayment;
