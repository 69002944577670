import React, { useEffect, useState } from 'react';
import AppContainer from '../../components/app-container';
import Indicator from '../../components/indicator';
import PieChart from './../../components/charts/PieChart/index';
import { createRecord, requestStatistic } from '../../transport/api';

import styles from './Balance.module.css';
import RecordTable from '../../components/RecordTable';
import AddTitle from '../../components/AddTitle';
import EditModal from './../../components/EditModal/index';
import PhysicalBalance from './PhysicalBalance';
import Investor from './Investor';
import MasterBalance from './MasterBalance';
import { getDate, makeTitle } from '../../utils';
import Button from '../../components/button';
import { toast } from 'react-toastify';

const Balance = () => {
  makeTitle('Баланс');
  const [data, setData] = useState({});
  const [newPhysicalBalance, toggleNewPhysicalBalance] = useState(false);
  const [newInvestor, toggleNewInvestor] = useState(false);
  const [investor, setInvestor] = useState();
  const [balance, setBalance] = useState();

  useEffect(() => {
    requestStatistic('/balances/summary').then((data) => setData(() => data));
  }, [newInvestor, investor?.id, balance?.id]);

  return (
    <AppContainer
      title={
        <div className={styles['button-container']}>
          Баланс
          {[0, 3, 6, 9].includes(new Date().getMonth()) ? <Button
            className={styles.button}
            onClick={() => createRecord(`/money-operations/new`, {
              operation_date: getDate(),
              operation_type: 'TAX',
              description: 'Оплата налога',
              is_shared: true,
            })
              .then(() => {
              toast.success('Налоги успешно списаны');
            })
              .catch((error) => toast.error(error.message))}
          >
            Списать налоги
          </Button> : undefined}
        </div>
      }
    >
      <div className={styles.indicators}>
        <Indicator
          label="Тотал баланса"
          value={(data?.total_balance ?? 0) + (data?.stock_balance ?? 0) - (data?.tax_balance ?? 0)}
          measure="₽"
        />
        <Indicator
          label="Вложения / доля инвесторов"
          value={data?.total_investment}
          measure="₽"
        />
        <Indicator
          label="Свободных средств"
          value={data?.total_master_balance}
          measure="₽"
        />
        <Indicator
          label="Денежный баланс"
          value={data?.total_balance}
          measure="₽"
        />
        <Indicator
          label="Товарный баланс"
          value={data?.stock_balance}
          measure="₽"
        />
        <Indicator
          label="Неоплаченный налог"
          value={data?.tax_balance}
          measure="₽"
        />
      </div>
      <div className={styles['chart-container']}>
        <RecordTable
          title={
            <AddTitle
              text="Физические балансы"
              onAddClick={() => toggleNewPhysicalBalance(true)}
            />
          }
          columns={[
            { name: 'name', title: 'Название' },
            { name: 'current_value', title: 'Сумма', type: 'number' },
          ]}
          resourcePath="/physical-balances"
          forceUpdate={newPhysicalBalance}
          defaultSorting={[{ id: 'value', desc: true }]}
        />
        <PieChart data={data?.physical_balance} title="Физические балансы" />
        <PieChart
          data={data?.goods?.map((item) => ({ name: item.name, value: item.current_value }))}
          title="Распределение по проектам"
        />
        <PieChart data={data?.investors} title="Доля инвесторов" />
      </div>
      <div className={styles.container}>
      <RecordTable
          title="Свободные средства"
          columns={[
            { name: 'physical_balance_name', title: 'Физический баланс' },
            { name: 'initial', title: 'Первоначальный баланс', type: 'number' },
            {
              name: 'initial_date',
              title: 'Дата первоначального баланса',
              type: 'date',
            },
            { name: 'cash_current_value', title: 'Текущий остаток', type: 'number' },
          ]}
          resourcePath={`/balances`}
          onClick={(row) =>
            setBalance({
              id: row.id,
              initial: row.initial,
              name: row.name,
              initial_date: row.initial_date,
            })
          }
          forceUpdate={!!balance?.id}
          defaultSorting={[{ id: 'cash_current_value', desc: true }]}
        />
      </div>
      <EditModal
        isOpen={newPhysicalBalance}
        close={() => toggleNewPhysicalBalance(false)}
      >
        <PhysicalBalance close={() => toggleNewPhysicalBalance(false)} />
      </EditModal>
      <EditModal
        isOpen={newInvestor || !!investor?.id}
        close={() => {
          toggleNewInvestor(false);
          setInvestor(undefined);
        }}
      >
        <Investor
          investorId={investor?.id}
          investorType={investor?.type}
          investorName={investor?.name}
          forCreate={newInvestor}
          close={() => {
            toggleNewInvestor(false);
            setInvestor();
          }}
        />
      </EditModal>
      <EditModal close={() => setBalance(undefined)} isOpen={!!balance?.id}>
          <MasterBalance
            close={() => setBalance()}
            balance={balance}
          />
        </EditModal>
    </AppContainer>
  );
};

export default Balance;
