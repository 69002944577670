import React from 'react';
import Header from './header';

import styles from './AppContainer.module.css';

const AppContainer = ({ title, filters, children, containerClassname }) => {
  return (
    <div className={styles.container}>
      <Header title={title}>
        {filters?.map((filter, i) => <div key={i}>{filter}</div>)}
      </Header>
      <div className={containerClassname}>
        {children}
      </div>
    </div>
  )
}

export default AppContainer
