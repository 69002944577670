import React from 'react';
import { useForm } from 'react-hook-form';
import {
  createRecord,
  requestEntity,
  updateRecord,
} from './../../transport/api';
import Input from '../../components/input';
import UncontrolledSelect from '../../components/UncontrolledSelect';

import styles from './User.module.css';
import ComponentContainer from './../../components/ComponentContainer/index';
import Switch from '../../components/Switch';
import Button from './../../components/button/index';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const User = ({ userId, forCreate, close, fromProfile = false }) => {
  const { register, handleSubmit, control } = useForm({
    defaultValues: async () =>
      !forCreate
        ? requestEntity('/users', userId).then((data) => ({
            ...data,
            deactivated: `deactivated_${data.deactivated}`,
          }))
        : { deactivated: 'deactivated_false', role: 'STANDARD' },
  });

  const onSubmit = async (data) => {
    const processedData = {
      ...data,
      deactivated: data.deactivated === 'deactivated_true',
    };
    if (forCreate) {
      return createRecord('/users/new', processedData)
        .then(() => {
          toast.success('Пользователь успешно добавлен');
          close();
        })
        .catch((error) => toast.error(error.message));
    }
    return updateRecord(`/users/${userId}`, processedData)
      .then(() => {
        toast.success('Данны пользователя успешно обновлены');
        close();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <ComponentContainer className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} id="user-form">
        <Input
          label="Имя пользователя"
          register={() =>
            register('username', { required: true, disabled: !forCreate })
          }
        />
        <Input
          label="Электронная почта"
          register={() =>
            register('email', { required: true, disabled: !forCreate })
          }
        />
        <Input
          label="Пароль"
          type="password"
          placeholder="••••••••"
          register={() => register('password', { required: forCreate })}
        />
        <Input
          label="ФИО"
          register={() => register('fullname', { required: true })}
        />
        {!fromProfile ? (
          <UncontrolledSelect
            label="Роль"
            resourcePath="/roles"
            disabled={Cookies.get('role') !== 'ADMIN'}
            control={control}
            optionsColumns={{ value: 'key', label: 'value' }}
            name="role"
          />
        ) : undefined}
        {!fromProfile ? (
          <Switch
            label="Деактивирован?"
            options={[
              { value: 'deactivated_true', label: 'Да' },
              { value: 'deactivated_false', label: 'Нет' },
            ]}
            register={() =>
              register('deactivated', {
                disabled: Cookies.get('role') !== 'ADMIN',
              })
            }
          />
        ) : undefined}
        {!fromProfile ? (
          <UncontrolledSelect
            control={control}
            name="group_id"
            label="Группа"
            resourcePath="/groups"
            optionsColumns={{ value: 'id', label: 'name' }}
          />
        ) : undefined}
      </form>
      <Button form="user-form" type="submit">
        Сохранить
      </Button>
    </ComponentContainer>
  );
};

export default User;
