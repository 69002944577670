import React from 'react';

import styles from './Input.module.css';

const Input = ({
  type,
  value,
  onChange,
  label,
  placeholder,
  containerClassName,
  className,
  register,
  isFilter,
  options,
  hidden,
  disabled,
  step,
  defaultValue,
}) => {
  return (
    <div
      className={`${styles.container} ${containerClassName} ${
        isFilter ? styles['filter-container'] : ''
      } ${hidden ? styles['container-hidden'] : ''}`}
    >
      {label ? <div className={styles.label}>{label}</div> : undefined}
      <input
        type={type}
        value={value}
        step={step}
        defaultValue={defaultValue}
        onChange={onChange}
        placeholder={placeholder}
        hidden={hidden}
        disabled={disabled}
        onWheel={(e) => e.currentTarget.blur()}
        className={`${styles.input} ${className} ${
          isFilter ? styles.white : ''
        }`}
        {...(register && register())}
        {...options}
      />
    </div>
  );
};

export default Input;
